import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Card1 from "../../components/Card1";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function AdminProfile() {
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const navigate = useNavigate();
  const { userId } = useParams();

  const [user, setUser] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.responseStatusCode === 403) {
          navigate("/login");
        }
        setUser(response.data.responseData.admin);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError("All fields are required");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password must match");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/auth/changePassword`,
        { password: oldPassword, newPassword: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        alert(response.data.responseMessage);
        navigate("/login");
        // setUrl(response.data.responseData.data.imgUrl);
        // setIsloading(false);
      })
      .catch((error) => {
        alert(error.response.data.responseMessage);
        console.log("Something went wrong");
        console.error({ error });
      });
  };
  return (
    <Card1>
      <div className=" flex gap-10">
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row gap-5 w-full">
            <div className="ring-8 ring-stroke m-2 h-fit max-w-sm">
              <img
                src={user.profileImage}
                className="w-full md:w-[30rem]"
              ></img>
            </div>
            <div className="flex flex-col w-full ">
              <div>
                <div className="flex justify-between bg-gray px-4 py-2 text-black text-2xl">
                  <div className="font-semibold">Admin Profile</div>
                </div>
                <div>
                  {isLoading ? (
                    <Loader></Loader>
                  ) : (
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <th className="whitespace-nowrap py-4 text-start max-w-13 border-b border-[#eee]  px-4 font-bold  text-black dark:text-white ">
                            Name:
                          </th>
                          <td className="border-b border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                            {user.name}
                          </td>
                        </tr>
                        <tr>
                          <th className="whitespace-nowrap py-4 text-start max-w-13 border-b border-[#eee]  px-4 font-bold  text-black dark:text-white ">
                            Mobile:
                          </th>
                          <td className="border-b border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                            {user.mobileNo}
                          </td>
                        </tr>
                        <tr>
                          <th className="whitespace-nowrap py-4 text-start max-w-13 border-b border-[#eee]  px-4 font-bold  text-black dark:text-white ">
                            Email:
                          </th>
                          <td className="border-b border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                            {user.email}
                          </td>
                        </tr>
                        <tr>
                          <th className="whitespace-nowrap py-4 text-start max-w-13 border-b border-[#eee]  px-4 font-bold  text-black dark:text-white ">
                            Gender:
                          </th>
                          <td className="border-b border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                            {user.gender}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <div className=" flex flex-wrap justify-start mt-4 gap-4 ">
                    <button
                      onClick={() => {
                        navigate("/profile/update");
                      }}
                      class="block w-full md:w-40 whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
                    >
                      Update Profile
                    </button>
                    <button
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      class="block w-full md:w-40 whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
                    >
                      Change Password
                    </button>
                    <button
                      onClick={() => {
                        sessionStorage.setItem("kings_club_admin_token", "");
                        localStorage.setItem("kings_club_admin_token", "");
                        navigate("/login");
                      }}
                      class="block w-full md:w-40 whitespace-nowrap rounded border  bg-danger py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles}
        >
          <div className="py-10 px-5">
            <form className="flex flex-col gap-4 mb-10 items-start min-w-[500px] text-lg  max-w-xl">
              <input
                className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                type="password"
                placeholder="Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <input
                className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {error && <p className="text-red-500 my-2">{error}</p>}
            </form>
            <div className="-mx-3 flex flex-wrap gap-y-4">
              <div className="w-1/2  px-3">
                <button
                  class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                  onClick={handleResetPassword}
                >
                  Submit
                </button>
              </div>
              <div class="w-1/2  px-3">
                <button
                  onClick={() => setIsOpen(false)}
                  class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Card1>
  );
}

export default AdminProfile;
