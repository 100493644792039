import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import UsersNav from "./UsersNav";

import Modal from "react-modal";

import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function UserOtherInfo() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [referrShare, setReferrShare] = useState("");
  const [adminShareLudochallenges, setAdminShareLudochallenges] = useState("");

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setUser(response.data.responseData.user);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, [modalIsOpen]);
  const submitHandler = async (event) => {
    event.preventDefault();
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}/ludoSettings`,
      { referrShare, adminShareLudochallenges },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    closeModal();
  };
  return (
    <div class="rounded-sm border min-h-screen border-stroke bg-white md:p-7.5 p-3 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col w-full">
        <UsersNav userId={userId} />

        <div className="flex gap-5 w-full">
          <div className="flex flex-col w-full">
            <div>
              <div className="flex justify-start  mb-8  gap-4">
                <button
                  className="inline-flex text-sm w-35 items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                  onClick={openModal}
                >
                  Update
                </button>
              </div>
              <div>
                {isLoading ? (
                  <Loader></Loader>
                ) : (
                  <table className="w-full border border-stroke">
                    <tbody>
                      <tr className="border-b border-[#eee] hover:bg-gray-2  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <th className=" text-left p-2 w-1/3">
                          adminShareLudochallenges:
                        </th>
                        <td className=" w-1/3">
                          {user.adminShareLudochallenges}
                        </td>
                      </tr>
                      <tr className="border-b border-[#eee] hover:bg-gray-2  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <th className=" text-left p-2 w-1/3">referrShare:</th>
                        <td className="">{user.referrShare}</td>
                      </tr>
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                      >
                        <div class="md:px-12 border border-stroke w-full max-w-lg rounded-lg bg-white px-8 py-8 text-center dark:bg-boxdark md:py-15">
                          <h3 class="pb-2 text-xl bg-gray-2 font-bold text-black dark:text-white sm:text-2xl">
                            Update Ludo Settings
                          </h3>
                          <span class="mx-auto  mb-6 inline-block h-1 w-full rounded bg-primary"></span>

                          <form
                            className="flex flex-col items-start min-w-96 text-lg  max-w-xl"
                            onSubmit={submitHandler}
                          >
                            <label
                              for="adminShareLudochallenges"
                              className="mt-4 text-zinc-700 font-bold"
                            >
                              adminShareLudochallenges:
                            </label>
                            <input
                              id="adminShareLudochallenges"
                              type="text"
                              onChange={(e) =>
                                setAdminShareLudochallenges(e.target.value)
                              }
                              className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            />
                            <label
                              for="referrShare"
                              className="mt-4 text-zinc-700 font-bold"
                            >
                              Referer Share:
                            </label>
                            <input
                              id="referrShare"
                              type="text"
                              onChange={(e) => setReferrShare(e.target.value)}
                              className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            />
                            <div className="w-full mt-10">
                              <div class="flex justify-evenly gap-4">
                                <button
                                  type="submit"
                                  class="block w-full whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
                                >
                                  Update
                                </button>
                                <button
                                  onClick={closeModal}
                                  class="block w-full rounded border border-stroke bg-gray py-1 px-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Modal>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserOtherInfo;
