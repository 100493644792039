import React from "react";
import Login from "./pages/Login";

import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Home from "./pages/Home";
import Users from "./pages/users/Users";
import Ludo from "./pages/ludo/Ludo";
import UserTransactions from "./pages/users/UserTransactions";
import UserPersonalInfo from "./pages/users/UserPersonalInfo";
import UserWalletInformation from "./pages/users/UserWalletInformation";
import UsersLudoHistory from "./pages/users/UsersLudoHistory";
import UsersTournamnetsHistory from "./pages/users/UsersTournamnetsHistory";
import Games from "./pages/games/Games";
import "./App.css";
import AddGame from "./pages/games/AddGame";
import UpdateGames from "./pages/games/UpdateGames";
import AddAmountTouserWallet from "./pages/users/AddAmountTouserWallet";
import Transactions from "./pages/transactions/Transactions";
import WithdrawalReq from "./pages/withdrawal/WithdrawalReq";
import Contacts from "./pages/Contacts";
import Updates from "./pages/updates/Updates";
import AddUpdates from "./pages/updates/AddUpdates";
import ReferralSettings from "./pages/Settings/ReferralSettings";
import ReferralSettingsUpdate from "./pages/Settings/ReferralSettingsUpdate";
import Deduction from "./pages/deductions/Deduction";
import DeductionUpdate from "./pages/deductions/DeductionUpdate";
import AdminProfile from "./pages/admin/AdminProfile";
import UserOtherInfo from "./pages/users/UserOtherInfo";
import AdminProfileUpdate from "./pages/admin/AdminProfileUpdate";
import LudoChallengeById from "./pages/ludo/LudoChallengeById";
import Tournaments from "./pages/tournaments/Tournaments";
import ViewChats from "./pages/ludo/ViewChats";
import TournamentById from "./pages/tournaments/TournamentById";
import AddNewTournaments from "./pages/tournaments/AddNewTournaments";
import ChallengeTeams from "./pages/tournaments/ChallengeTeams";
import Team from "./pages/tournaments/TeamById";
import UpdateTournaments from "./pages/tournaments/UpdateTournaments";
import Error from "./pages/Error";
import Prizepool from "./pages/tournaments/Prizepool";

function App() {
  return (
    <div className="font-myFont">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route index element={<Home />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route
            path="/users/:userId/personalInfo"
            element={<UserPersonalInfo />}
          />
          <Route
            path="/users/:userId/tansactions"
            element={<UserTransactions />}
          />
          <Route
            path="/users/:userId/wallet"
            element={<UserWalletInformation />}
          />
          <Route
            path="/users/:userId/addAmount"
            element={<AddAmountTouserWallet />}
          />
          <Route path="/users/:userId/ludo" element={<UsersLudoHistory />} />
          <Route
            path="/users/:userId/tournaments"
            element={<UsersTournamnetsHistory />}
          />
          <Route path="/users/:userId/otherInfo" element={<UserOtherInfo />} />
          <Route path="/ludo" element={<Ludo />} />
          <Route path="/ludo/:ludoId" element={<LudoChallengeById />} />
          <Route path="/ludo/:ludoId/chats" element={<ViewChats />} />
          <Route path="/games" element={<Games />} />
          <Route path="/tournaments" element={<Tournaments />} />
          <Route
            path="/tournaments/:tournamentId"
            element={<TournamentById />}
          />
          <Route path="/tournaments/addNew" element={<AddNewTournaments />} />
          <Route
            path="/tournaments/:tournamentId/update"
            element={<UpdateTournaments />}
          />
          <Route
            path="/tournaments/:tournamentId/teams"
            element={<ChallengeTeams />}
          />
          <Route
            path="/tournaments/:tournamentId/prizePool"
            element={<Prizepool />}
          />
          <Route path="/teams/:teamId" element={<Team />} />
          <Route path="/games/addNewGame" element={<AddGame />} />
          <Route path="/games/:gameId/update" element={<UpdateGames />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/withdrawalRequests" element={<WithdrawalReq />} />
          <Route path="/profile" element={<AdminProfile />} />
          <Route path="/profile/update" element={<AdminProfileUpdate />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/deduction" element={<Deduction />} />
          <Route path="/deduction/update" element={<DeductionUpdate />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/updates/add" element={<AddUpdates />} />
          <Route path="/settings" element={<ReferralSettings />} />
          <Route path="/error" element={<Error />} />
          <Route path="/prizepool" element={<Prizepool />} />
          <Route
            path="/settings/update/:documentId"
            element={<ReferralSettingsUpdate />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
