import React, { useEffect, useState } from "react";
import axios from "axios";
import { data } from "../../Data";
import { useNavigate } from "react-router-dom";
import Card1 from "../../components/Card1";

function AdminProfileUpdate() {
  const navigate = useNavigate();
  const [url, setUrl] = useState();
  const [name, setName] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const uploadImg = (e) => {
    setIsloading(true);
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    formData.append("image", selectedFile);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/admin/images`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setUrl(response.data.responseData.data.imgUrl);
        setIsloading(false);
      })
      .catch((error) => console.error({ error }));
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    // Create FormData object

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/profile`,
        { profileImage: url, name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("submitHandler", { response });
      navigate(`/profile`);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  return (
    <Card1>
      <div className="flex justify-center">
        <div class="md:px-17.5  border border-stroke w-full max-w-142.5 rounded-lg bg-white px-8 py-8 text-center dark:bg-boxdark md:py-15">
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-start min-w-96 text-lg  max-w-xl"
          >
            <label for="image" className="mt-4 text-zinc-700 font-bold">
              Profile Image:
            </label>
            <input
              type="file"
              id="image"
              onChange={(e) => uploadImg(e)}
              className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />

            <label for="adminName" className="mt-4 text-zinc-700 font-bold">
              Name:
            </label>
            <input
              id="adminName"
              type="text"
              onChange={(e) => setName(e.target.value)}
              className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            />

            <button
              type="submit"
              disabled={isLoading ? true : false}
              className={`block mt-6 w-full whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90 ${
                isLoading ? "cursor-wait" : "cursor-pointer"
              }`}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </Card1>
  );
}

export default AdminProfileUpdate;
