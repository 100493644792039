import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import Card1 from "../../components/Card1";
import PlayersCards from "../../components/PlayersCards";
import Loader from "../../components/Loader";
function TeamById() {
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const navigate = useNavigate();

  const { teamId } = useParams();

  const [team, setTeam] = useState({});
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/teams/${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setTeam(response.data.responseData.team);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Card1>
      <h3 className="text-2xl capitalize whitespace-nowrap  font-semibold text-black dark:text-white">
        Team Details
      </h3>
      <div className="border-b border-stroke py-2 mb-12 px-6.5 dark:border-strokedark"></div>

      {isLoading ? (
        <Loader></Loader>
      ) : (
        <div className="max-w-full  overflow-x-auto">
          <table className="w-full table-auto border border-stroke">
            <tbody>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Name
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.teamName}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Code:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.teamCode}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Owner Id:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.teamOwner}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Challenge Id:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.challengeId}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Size:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.teamSize}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Result Updated
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {`${team.isResultUpdated}`}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Rank:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.rank}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Result Id:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.resultId}
                </td>
              </tr>
              <tr>
                <th className="border-b border-r border-[#eee] text-start whitespace-nowrap py-2 px-4 font-medium text-black dark:text-white ">
                  Team Coin:
                </th>
                <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {team.teamCoin}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="border-b border-stroke py-2 mb-12 px-6.5 dark:border-strokedark"></div>

          <div className="">
            <h3 className="text-2xl capitalize whitespace-nowrap  font-semibold text-black dark:text-white">
              Team players
            </h3>
            <div className="border-b border-stroke py-2 mb-12 px-6.5 dark:border-strokedark"></div>

            <ul className="flex gap-10 justify-evenly flex-wrap">
              {team.teamPlayers.map((el, i) => {
                return <PlayersCards player={el} i={i}></PlayersCards>;
              })}
            </ul>
          </div>
        </div>
      )}
    </Card1>
  );
}

export default TeamById;
