import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";

import Modal from "react-modal";
import Loader from "../../components/Loader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
  },
};

function TournamentById() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { tournamentId } = useParams();
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [roomData, setRoomData] = useState({ roomId: "", password: "" });
  const [cancelLudoOpen, setCancelLudoOpen] = useState(false);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.challenge);
        setIsloading(false);
      })
      .catch((error) => alert("Something went wrong"));
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const cancelHandler = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}/cancel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        alert(response.data.responseMessage);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomData({
      ...roomData,
      [name]: value,
    });
  };

  const updateRoomCode = (e) => {
    // e.preventDefault();

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}/roomCode`,
        roomData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        alert(response.data.responseMessage);
      });
  };

  const handleFinished = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}/final`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        alert(response.data.responseMessage);
      });
  };

  return (
    <div class="rounded-sm border min-h-screen border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col w-full">
        <div>
          <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
            Tournament Challenge
          </div>

          <div className="flex justify-start  gap-4  overflow-x-auto your-container-class">
            <button
              onClick={() => setCancelLudoOpen(true)}
              disabled={apiData.status == "cancel" ? true : false}
              id="btn"
              className={`bg-red-500 w-35 text-sm  whitespace-nowrap ${
                apiData.status == "cancel" ? "hidden" : "cursor-pointer"
              } text-white shadow-sm   hover:shadow-none shadow-black p-1  px-3  rounded`}
            >
              {apiData.status == "cancel" ? "Canceled" : "Cancel"}
            </button>
            <button
              onClick={() => navigate(`/tournaments/${tournamentId}/update`)}
              id="btn"
              className="inline-flex w-35 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              Update Info
            </button>
            <button
              onClick={handleFinished}
              id="btn"
              className={`inline-flex w-35 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded ${
                apiData.status == "cancel" || apiData.status == "active"
                  ? "hidden"
                  : "cursor-pointer"
              }`}
            >
              Declare Finished
            </button>
            <button
              onClick={openModal}
              id="btn"
              className={`inline-flex w-35 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded ${
                apiData.status == "cancel" ? "hidden" : "cursor-pointer"
              }`}
            >
              Update RoomId
            </button>
            <button
              onClick={() => navigate(`/tournaments/${tournamentId}/prizePool`)}
              id="btn"
              className="inline-flex w-35 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              Prize Pool
            </button>
          </div>
          <div>
            {isLoading ? (
              <Loader></Loader>
            ) : (
              <div className="flex flex-col gap-4 md:flex-row">
                <table className="w-full mt-4 border border-stroke">
                  <tbody>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Game name</th>

                      <td className="capitalize">{apiData.gameName}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Title</th>
                      <td className="capitalize">{apiData.title}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Admin Share</th>
                      <td className="capitalize">{apiData.adminShare}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Challenge Type</th>
                      <td className="capitalize">{apiData.challengeType}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Mode</th>

                      <td className="capitalize">{apiData.mode}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Map</th>
                      <td className="capitalize">{apiData.map}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Entry Fee</th>
                      <td className="capitalize">{apiData.entryFee}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Total Participants</th>
                      <td className="capitalize">
                        {apiData.totalParticipants}
                      </td>
                    </tr>

                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">
                        Total Teams Joined{" "}
                        <span
                          onClick={() =>
                            navigate(`/tournaments/${tournamentId}/teams`)
                          }
                          className="bg-red-500 cursor-pointer text-white  ml-10 text-sm px-3 rounded-full font-bold"
                        >
                          View
                        </span>
                      </th>
                      <td className="capitalize">
                        {apiData.totalParticipantsjoined}
                      </td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Coins/Kill</th>
                      <td className="capitalize">{apiData.coinPerkill}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Prize Amount</th>
                      <td className="capitalize">{apiData.prizeAmount}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Status</th>
                      <td className="capitalize">{apiData.status}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Starts At</th>
                      <td className="capitalize">
                        {format(apiData.startTime, "📆dd-MM-yyyy  🕐k:m:s")}
                      </td>
                    </tr>

                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Description</th>
                      <td className="capitalize">{apiData.description}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Room Id</th>
                      <td className="capitalize">{apiData.roomId}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="p-2 text-left">Password</th>
                      <td className="capitalize">{apiData.password}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="mt-4">
                  <img
                    src={apiData.uploadBanner}
                    className="mt-2 min-h-[200px] w-full max-w-sm mx-auto ring-8 ring-stroke"
                  ></img>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {/* <button onClick={openModal}>Open Modal</button> */}
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div class="md:px-17.5  w-full max-w-142.5 rounded-lg bg-white px-8 py-8 text-center dark:bg-boxdark md:py-15">
              <h3 class="pb-2 text-xl bg-gray-2 font-bold text-black dark:text-white sm:text-2xl">
                Add Amount To User Wallet
              </h3>
              <span class="mx-auto  mb-6 inline-block h-1 w-full rounded bg-primary"></span>

              <form className="flex flex-col items-start min-w-96 text-lg  max-w-xl">
                <label
                  htmlFor="roomId"
                  className="mt-4 text-zinc-700 font-bold"
                >
                  Room ID
                </label>
                <input
                  onChange={handleChange}
                  id="roomId"
                  name="roomId"
                  className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                ></input>

                <label
                  htmlFor="password"
                  className="mt-4 text-zinc-700 font-bold"
                >
                  Password
                </label>
                <input
                  onChange={handleChange}
                  name="password"
                  id="password"
                  className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                ></input>

                <div className="w-full mt-10">
                  <div class="flex justify-evenly gap-4">
                    <button
                      onClick={updateRoomCode}
                      type="submit"
                      class="block w-full whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
                    >
                      Submit
                    </button>

                    <button
                      onClick={() => closeModal()}
                      class="block w-full rounded border border-stroke bg-gray py-1 px-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
      <Modal
        isOpen={cancelLudoOpen}
        onRequestClose={() => setCancelLudoOpen(false)}
        style={customStyles}
      >
        <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
          <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            Confirm Action
          </h3>
          <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

          <p class="mb-10">Are you sure you want to cancel the challenge?</p>
          <div class="-mx-3 flex flex-wrap gap-y-4">
            <div class="w-1/2  px-3">
              <button
                onClick={() => {
                  setCancelLudoOpen(false);
                  cancelHandler();
                }}
                class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Yes
              </button>
            </div>
            <div class="w-1/2  px-3">
              <button
                onClick={() => setCancelLudoOpen(false)}
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TournamentById;
