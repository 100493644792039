import React from "react";
import { useNavigate } from "react-router-dom";
import { FaAlignJustify, FaUserLarge } from "react-icons/fa6";

function Header({ toggleNav }) {
  const navigate = useNavigate();
  return (
    <div className="flex text-2xl justify-between lg:justify-end px-5 py-2 shadow-1 bg-white">
      <FaAlignJustify
        onClick={toggleNav}
        className="cursor-pointer translate-y-3 lg:hidden text-blue-500"
      />
      <div className="flex w-32 text-xl justify-evenly ">
        <div
          onClick={() => navigate(`/profile`)}
          className="bg-blue-100 rounded-full p-3  cursor-pointer text-blue-500 hover:bg-blue-200 hover:text-blue-700"
        >
          <FaUserLarge />
        </div>
      </div>
    </div>
  );
}

export default Header;
