import axios from "axios";
import React, { useEffect, useState } from "react";
import { data } from "../../Data";
import { useNavigate, useParams } from "react-router-dom";
import Card1 from "../../components/Card1";

function UpdateTournaments() {
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [games, setGames] = useState([]);

  const [formData, setFormData] = useState({
    gameName: "",
    title: "",
    description: "",
    adminShare: "",
    challengeType: 0,
    mode: "",
    map: "",
    entryFee: 0,
    coinPerkill: 0,
    prizeAmount: 0,
    startTime: "",
    totalParticipants: 0,
    // prizePool: [],
    uploadBanner: "",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/admin/games`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setGames(response.data.responseData.games);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setFormData({
          gameName: response.data.responseData.challenge.gameName,
          title: response.data.responseData.challenge.title,
          description: response.data.responseData.challenge.description,
          adminShare: response.data.responseData.challenge.adminShare,
          challengeType: response.data.responseData.challenge.challengeType,
          mode: response.data.responseData.challenge.mode,
          map: response.data.responseData.challenge.map,
          entryFee: response.data.responseData.challenge.entryFee,
          coinPerkill: response.data.responseData.challenge.coinPerkill,
          prizeAmount: response.data.responseData.challenge.prizeAmount,
          startTime: response.data.responseData.challenge.startTime,
          totalParticipants:
            response.data.responseData.challenge.totalParticipants,
          uploadBanner: response.data.responseData.challenge.uploadBanner,
        });
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // navigate("/tournaments");
      })
      .catch((error) => console.log("error", error));
  };
  const uploadImg = (e) => {
    const selectedFile = e.target.files[0];

    const imgformData = new FormData();
    imgformData.append("image", selectedFile);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/admin/images`, imgformData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setFormData({
          ...formData,
          uploadBanner: response.data.responseData.data.imgUrl,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Card1 title={"Update Tournament"}>
      <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
        Update tournament
      </div>
      <form onSubmit={handleSubmit}>
        <div className="border border-stroke p-6.5">
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="flex-1">
              <div className="pt-1 text-center">
                <div className="">
                  <img
                    src={formData.uploadBanner}
                    alt="banner"
                    className="min-h-[200px] w-full max-w-sm mx-auto ring-8 ring-stroke m-7"
                  ></img>
                </div>
              </div>
            </div>
            <div className="flex-1">
              {/* upload image */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="banner">
                    Select Banner
                  </label>
                  <input
                    id="banner"
                    onChange={(e) => uploadImg(e)}
                    type="file"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  ></input>
                </div>
              </div>
              {/* Title */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="title">
                    Title
                  </label>
                  <input
                    id="title"
                    name="title"
                    value={formData.title}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    type="text"
                    placeholder={"Title"}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Select game */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="game">
                    Select Game
                  </label>
                  <select
                    onChange={handleChange}
                    name="gameName"
                    id="game"
                    value={formData.gameName}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  `}
                  >
                    {games.map((game) => (
                      <option value={game.gameName}>{game.gameName}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* starts at*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="startsAt"
                  >
                    Starts at
                  </label>
                  <input
                    id="startsAt"
                    name="startTime"
                    value={
                      formData.startTime
                        ? new Date(formData.startTime)
                            .toISOString()
                            .slice(0, 16)
                        : ""
                    }
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    type="datetime-local"
                    placeholder={"startsAt"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Chalenge type*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="challengeType"
                  >
                    Challenge Type
                  </label>
                  <select
                    onChange={handleChange}
                    name="challengeType"
                    id="challengeType"
                    value={formData.challengeType}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  `}
                  >
                    <option value={1}>Solo</option>
                    <option value={2}>Duo</option>
                    <option value={3}>Squad</option>
                  </select>
                </div>
              </div>

              {/* Mode*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="mode">
                    mode
                  </label>
                  <input
                    id="mode"
                    name="mode"
                    value={formData.mode}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary `}
                    type="text"
                    placeholder={"mode"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* 4 */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="adminShare"
                  >
                    Admin Share
                  </label>
                  <input
                    id="adminShare"
                    name="adminShare"
                    value={formData.adminShare}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    type="number"
                    placeholder={5}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* prize amount */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="prizeAmount"
                  >
                    Prize Amount
                  </label>
                  <input
                    id="prizeAmount"
                    name="prizeAmount"
                    value={formData.prizeAmount}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary `}
                    type="number"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* map */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="map">
                    Map
                  </label>
                  <input
                    id="map"
                    name="map"
                    value={formData.map}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* points per kill */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="coinPerkill"
                  >
                    Points/Kill
                  </label>
                  <input
                    id="coinPerkill"
                    name="coinPerkill"
                    type="text"
                    value={formData.coinPerkill}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Entry fee */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="entryFee"
                  >
                    Entry Fee
                  </label>
                  <input
                    id="entryFee"
                    name="entryFee"
                    value={formData.entryFee}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary `}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* total participants*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="totalParticipants"
                  >
                    Total Participants
                  </label>
                  <input
                    id="totalParticipants"
                    name="totalParticipants"
                    value={formData.totalParticipants}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary`}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* description */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary `}
                    placeholder="text here....."
                    rows={7}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
          <button
            type="submit"
            className="inline-flex items-center justify-center rounded-md bg-primary py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
          >
            Submit
          </button>
        </div>
      </form>
    </Card1>
  );
}

export default UpdateTournaments;
