import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";
import { data } from "../../Data";

// modal start

import Modal from "react-modal";
import Card1 from "../../components/Card1";
import Loader from "../../components/Loader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "90%",
  },
};

// modal end

const tableStyle = {
  border: "1px solid black",
  borderCollapse: "collapse",
  textAlign: "center",
  width: "100%",
};

const tdStyle = {
  border: "1px solid #85C1E9",
  background: "white",
  padding: "5px",
};

const thStyle = {
  border: "1px solid #3498DB",
  color: "white",
  padding: "5px",
};

function ChallengeTeams() {
  // modal start
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // modal end
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const [dataPerPage, setDataPerPage] = useState(15);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);
  const pages = [...Array(numOfTotalPages).keys()].slice(1);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    const getData = async () => {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/v1/admin/tournamnet/${tournamentId}/teams?skip=${
          (currentPage - 1) * 10
        }&limit=${dataPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.responseData.teams);
      setApiData(response.data.responseData.teams);
      setTotalDataCount(response.data.responseData.dataCount);
      setIsloading(false);
    };

    getData();
  }, [currentPage]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1>
      <h3 className="text-2xl capitalize whitespace-nowrap  font-semibold text-black dark:text-white">
        Teams
      </h3>
      <div className="border-b border-stroke py-2 mb-12 px-6.5 dark:border-strokedark"></div>

      <div className="">
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="max-w-full  overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="border-b border-stone-300">
                <tr className="bg-gray shadow-inner capitalize  text-left dark:bg-meta-4">
                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    teamName
                  </th>
                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    teamCode
                  </th>
                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    teamOwnerId
                  </th>

                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    isResultUpdated
                  </th>

                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    resultId
                  </th>
                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    teamCoin
                  </th>
                  <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                    actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {apiData.length == 0 ? (
                  <h1>Team not joined yet</h1>
                ) : (
                  apiData.map((el) => (
                    <tr key={el._id}>
                      <td className="border-b border-[#eee] text-sm  p-1  dark:border-stone-300dark ">
                        {el.teamName ? el.teamName : "Solo Team"}
                      </td>
                      <td className="border-b border-[#eee] text-sm  p-1  dark:border-stone-300dark ">
                        {el.teamCode}
                      </td>
                      <td className="border-b border-[#eee] text-sm  p-1  dark:border-stone-300dark ">
                        {el.teamOwner}
                      </td>

                      <td className="border-b border-[#eee] text-sm pl-9 capitalize  p-1  dark:border-stone-300dark ">
                        {`${el.isResultUpdated}`}
                      </td>

                      <td className="border-b border-[#eee] text-sm  p-1  dark:border-stone-300dark ">
                        {el.isResultUpdated ? el.resultId : "NA"}
                      </td>
                      <td className="border-b border-[#eee] text-sm pl-9 p-1  dark:border-stone-300dark ">
                        {el.teamCoin}
                      </td>
                      <td className="border-b border-[#eee] text-sm  p-1  dark:border-stone-300dark ">
                        <div className="flex gap-2">
                          <button
                            onClick={openModal}
                            className="inline-flex text-sm items-center justify-center bg-black-2 capitalize w-14 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                          >
                            Result
                          </button>
                          <button
                            // onClick={openModal}
                            onClick={() => navigate(`/teams/${el._id}`)}
                            className="inline-flex text-sm items-center justify-center bg-black-2 capitalize w-14 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                          >
                            view
                          </button>
                        </div>

                        <Modal
                          isOpen={modalIsOpen}
                          // onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                          <div className=" flex justify-between text-xl w-96 text-white  items-baseline bg-blue-500 p-2">
                            <h2 className="font-bold">Result of a team</h2>
                            <button
                              className="bg-red-500 px-3 w-20   py-1 rounded-full"
                              onClick={closeModal}
                            >
                              close
                            </button>
                          </div>
                          <div className="flex justify-center items-center"></div>
                        </Modal>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
        <nav className="flex flex-wrap items-center">
          {/* Previous page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={prevPageHandler}
          >
            {"<"}
          </span>
          {/* Render page numbers */}
          {renderPages()}
          {/* Next page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={nextPageHandler}
          >
            {">"}
          </span>
        </nav>
      </div>
    </Card1>
  );
}

export default ChallengeTeams;
