import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Card1 from "../../components/Card1";
import Loader from "../../components/Loader";

function Transactions() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [searchBy, setSearchBy] = useState("");
  const [from, SetFrom] = useState("");
  const [to, setTo] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [gameName, setGameName] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [games, setGames] = useState([]);
  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/admin/games`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setGames(response.data.responseData.games);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    setIsloading(true);

    const getData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/v1/admin/transactions?search=${searchBy}&from=${from}&transactionType=${transactionType}&gameName=${gameName}&to=${to}&skip=${
            (currentPage - 1) * dataPerPage
          }&limit=${dataPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.transactions);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, [currentPage, to, from, searchBy, transactionType, gameName]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };

  return (
    <Card1 title={"Recent Transactions"}>
      <div className="max-w-full  overflow-x-auto  your-container-class">
        <div className="flex  justify-start pb-5 my-5 gap-4">
          <input
            type="text"
            className="form-datepicker w-50  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="search by mobile number"
            onChange={(e) =>
              setSearchBy(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))
            }
          />
          <div className="whitespace-nowrap mx-5">
            From:
            <input
              type="date"
              className="form-datepicker w-50  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(e) => SetFrom(e.target.value)}
            />
          </div>
          <div className="whitespace-nowrap mx-5">
            To:
            <input
              type="date"
              className="form-datepicker w-50  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <select
            className="form-datepicker w-50  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            value={transactionType}
            onChange={(event) => setTransactionType(event.target.value)}
          >
            <option value="">Transaction Type</option>
            <option value="">All</option>
            <option value="deposit">Deposit</option>
            <option value="win">Winning</option>
            <option value="withdraw">Withdrawn</option>
            <option value="challenge">Ludo Challenges</option>
            <option value="admin_credit">Deposited By Admin</option>
            <option value="admin_debit">Deducted By Admin</option>
          </select>

          <select
            className="form-datepicker w-50  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            onChange={(event) => setGameName(event.target.value)}
            value={gameName}
          >
            <option value="">All</option>
            <option value="ludo">Ludo</option>
            {games.map((game) => (
              <option value={game.gameName}>{game.gameName}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="max-w-full overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="border-b border-stone-300">
                <tr className="bg-gray shadow-inner text-left dark:bg-meta-4">
                  <th className="py-4  whitespace-nowrap  font-bold text-black dark:text-white ">
                    Sr. No.
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    User Name
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Phone Number
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Email
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Total Amount
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Game Name
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Transaction Id
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Credit
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Debit
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Transaction Type
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white ">
                    Date and Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiData.map((el, i) => (
                  <tr key={el._id} className="hover:bg-gray-2">
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {i + 1 + (currentPage - 1) * dataPerPage}
                    </td>
                    <td
                      onClick={() =>
                        navigate(`/users/${el.userId.id}/personalInfo`)
                      }
                      className="border-b border-[#eee] text-sm  whitespace-nowrap py-2 px-4  dark:border-strokedark  hover:underline cursor-pointer text-blue-500 capitalize"
                    >
                      {el.userId?.name || ""}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.userId?.mobileNo || ""}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.userId?.email || ""}
                    </td>
                    <td className="border-b bg-blue-100 border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.amount}
                    </td>
                    <td className="border-b capitalize border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.gameType}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el._id}
                    </td>

                    <td className="border-b bg-green-100 border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.transactionType === "win" ||
                      el.transactionType === "deposit" ||
                      el.transactionType === "bonus" ||
                      el.transactionType === "refund" ||
                      el.transactionType === "referal" ||
                      el.transactionType === "admin_credit" ||
                      el.transactionType === "admin"
                        ? el.amount
                        : null}
                    </td>

                    <td className="border-b bg-red-100 border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.transactionType === "withdraw" ||
                      el.transactionType === "admin_debit" ||
                      el.transactionType === "lost" ||
                      el.transactionType === "challenge"
                        ? el.amount
                        : null}
                    </td>

                    <td className="border-b border-[#eee] text-sm capitalize whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {el.transactionType}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap py-2 px-4  dark:border-strokedark ">
                      {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
        <nav className="flex flex-wrap items-center">
          {/* Previous page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={prevPageHandler}
          >
            {"<"}
          </span>
          {/* Render page numbers */}
          {renderPages()}
          {/* Next page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={nextPageHandler}
          >
            {">"}
          </span>
        </nav>
      </div>
    </Card1>
  );
}

export default Transactions;
