import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/admin/auth/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.responseStatus) {
          sessionStorage.setItem(
            "kings_club_admin_token",
            response.data.responseData.token
          );

          if (rememberMe) {
            localStorage.setItem(
              "kings_club_admin_token",
              response.data.responseData.token
            );
          }
        }
        navigate("/");
      })
      .catch((error) => {
        if (error.response) {
          return alert(error.response.data.responseMessage);
        }

        alert("Something went wrong");
      });
  };

  return (
    <div className="bg-[#f1f5f9]">
      <div className="flex items-center justify-center  max-w-lg mx-auto min-h-screen px-2">
        <div className="rounded-sm border max-w-lg border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 pt-10 dark:border-strokedark">
            <h3 className="font-semibold uppercase w-fit mx-auto text-center border-b border-stroke pb-2 text-black dark:text-white">
              Kings Club Admin Login
            </h3>

            <form onSubmit={handleSubmit} className="flex flex-col mt-5 p-5">
              <div className="p-2 md:p-6.5">
                <div className="mb-4.5 space-y-5">
                  <input
                    type="email"
                    placeholder="example@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stone-400 bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />

                  <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full rounded border-[1.5px] border-stone-400 bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <input
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    value="rememberMe"
                    onChange={(e) => setRememberMe(e.target.value)}
                    className="mr-2 border-[1.5px] border-stroke custom-checkbox"
                  ></input>
                  <label htmlFor="rememberMe">Remember me</label>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                  >
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
