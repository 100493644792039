import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import UsersNav from "./UsersNav";
import Modal from "react-modal";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function UserPersonalInfo() {
  const navigate = useNavigate();

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setUser(response.data.responseData.user);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);
  const deleteUser = async () => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatus) {
          alert(response.data.responseMessage);
          navigate("/users");
        }
      });
  };
  return (
    <div class="rounded-sm border min-h-screen border-stroke bg-white p-3 md:p-7.5  shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col w-full">
        <UsersNav userId={userId} />

        <div>
          <div className="flex justify-start  mb-8  gap-4">
            <button
              onClick={() => {
                // setUserId(el.id);
                setIsOpen(true);
              }}
              className="inline-flex whitespace-nowrap text-sm items-center min-w-[8.75rem] justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              {user.isDeleted ? (
                <p>User already deleted</p>
              ) : (
                <p>Delete User</p>
              )}
            </button>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center">
              <Loader></Loader>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row gap-5 w-full">
              <div className="flex flex-col w-full">
                <table className="w-full border border-stroke">
                  <tbody>
                    <tr className="border-b border-[#eee] hover:bg-gray-2  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2 ">Name:</th>
                      <td className="">{user.name}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Mobile Number:</th>
                      <td className="">{user.mobileNo}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Email:</th>
                      <td className="">{user.email}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Gender:</th>
                      <td className="">{user.gender}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Invite Code:</th>
                      <td className="">{user.inviteCode}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Referred By:</th>
                      <td
                        onClick={() => {
                          user.invitedById &&
                            navigate(
                              `/users/${user.invitedById?._id}/personalInfo`
                            );

                          window.location.reload();
                        }}
                        className={` ${
                          user.invitedById
                            ? "hover:underline cursor-pointer"
                            : ""
                        }`}
                      >
                        {user?.invitedById?.name || "No Referral"}
                      </td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Aadhar Verified:</th>
                      <td className="">{`${user.aadhaarVerified}`}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Aadhar Number:</th>
                      <td className="">{user.aadharCardNumber}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="text-start p-2">Address on Aadhar:</th>
                      <td className="">{user.aadharAddress}</td>
                    </tr>
                    <tr className="border-b border-[#eee] hover:bg-gray-2 py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className=" text-start p-2">DOB:</th>
                      <td className="">{user.dateOfBirth}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full max-w-xs mt-2 px-1 mx-auto">
                <img
                  src={user.profileImage}
                  className="w-full ring-8 ring-stroke"
                ></img>
              </div>
            </div>
          )}
          {/*  */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
          >
            <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
              <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
                Confirm Action
              </h3>
              <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

              <p class="mb-10">
                Are you sure you want to delete this user? After deletion, the
                user will no longer have access to the app
              </p>
              <div class="-mx-3 flex flex-wrap gap-y-4">
                <div class="w-1/2  px-3">
                  <button
                    onClick={() => {
                      deleteUser(userId);
                      setIsOpen(false);
                    }}
                    class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                  >
                    Yes
                  </button>
                </div>
                <div class="w-1/2  px-3">
                  <button
                    onClick={() => setIsOpen(false)}
                    class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UserPersonalInfo;
