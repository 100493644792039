import React from "react";

function RejectModal({ setRejectIsOpen, handleWithdrawReq }) {
  return (
    <div class="md:px-17.5 w-full max-w-142.5 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
      <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
        Confirm Action !!
      </h3>
      <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>
      <p class="mb-10">
        Are you sure you want to reject this withdrawal request?
      </p>
      <div class="-mx-3 flex flex-wrap gap-y-4">
        <div class="2xsm:w-1/2 w-full px-3">
          <button
            onClick={() => setRejectIsOpen(false)}
            class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
          >
            Cancel
          </button>
        </div>
        <div class="2xsm:w-1/2 w-full px-3">
          <button
            onClick={() => {
              handleWithdrawReq("rejected");
              setRejectIsOpen(false);
            }}
            class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default RejectModal;
