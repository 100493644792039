import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import Modal from "react-modal";
import { format } from "date-fns";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function LudoChallengeById() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgIsOpen, setImgIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [cancelLudoOpen, setCancelLudoOpen] = useState(false);

  const navigate = useNavigate();

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const { ludoId } = useParams();
  const [ludo, setLudo] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [winner, setWinner] = useState("");
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/ludo/${ludoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setLudo(response.data.responseData.ludoChallenge);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const cancelLudoChallenge = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/ludo/${ludoId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              sessionStorage.getItem("kings_club_admin_token") ||
              localStorage.getItem("kings_club_admin_token")
            }`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        if (response.data.responseStatus) {
          setIsloading(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.responseMessage);
        }
      });
  };

  const declareWinner = () => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/ludo/${ludoId}/result`,
        { winner: winner },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        if (response.data.responseStatus) {
          alert(response.data.responseMessage);
          setIsloading(false);
          setTimeout(() => {
            window.location.reload(true);
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.responseMessage);
        }

        console.error(error);
      });
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div class="rounded-sm border min-h-screen border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col w-full">
        <div>
          <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
            Ludo Challenge
          </div>
          <div className="flex justify-start  gap-4">
            <button
              onClick={() => setCancelLudoOpen(true)}
              disabled={
                ludo.status === "cancel" || ludo.status === "	finished"
                  ? true
                  : false
              }
              id="btn"
              className={`inline-flex w-30 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded  ${
                ludo.status === "cancel" || ludo.status === "finished"
                  ? "hidden"
                  : ""
              } `}
            >
              Cancel
            </button>
            <button
              onClick={openModal}
              disabled={ludo.status != "pending" ? true : false}
              id="btn"
              className={`inline-flex w-30 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded  ${
                ludo.status != "pending" ? "hidden" : ""
              } `}
            >
              Declare Result
            </button>
            <button
              onClick={() => navigate(`/ludo/${ludo._id}/chats`)}
              id="btn"
              className={`inline-flex w-30 text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded`}
            >
              View Chats
            </button>
          </div>
          <div>
            {isLoading ? (
              <Loader></Loader>
            ) : (
              <div className="w-full  flex flex-col md:flex-row">
                <table className="flex-1">
                  <tbody>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">
                        ludo Challenge Name
                      </th>
                      <td className="">{ludo.ludoChalengeId}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Created At</th>
                      <td className="">
                        {format(ludo.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                      </td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Status</th>
                      <td className="">{ludo.status}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Coins</th>
                      <td className="">{ludo.coins}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Winner</th>
                      <td className="">{ludo.userWon?.name}</td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Creator winAmount</th>
                      <td className="">
                        {ludo.winAmount.creatorWinningAmount}
                      </td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">
                        Opponent winAmount
                      </th>
                      <td className="">
                        {ludo.winAmount.opponentWinningAmount
                          ? ludo.winAmount.opponentWinningAmount
                          : "NA"}
                      </td>
                    </tr>
                    <tr className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <th className="w-1/3 p-2 text-left">Room Code</th>
                      <td className="">
                        {ludo.roomCode ? ludo.roomCode : "NA"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex flex-col overflow-y-auto justify-between gap-10 flex-1 mt-10 md:mt-0">
                  {/* image uploaded by creator */}
                  <div className="">
                    <img
                      onClick={() => {
                        setImgIsOpen(true);
                        setImgUrl(ludo.creatorImage);
                      }}
                      className="w-[200px] mx-auto"
                      src={ludo.creatorImage}
                    ></img>
                    <h1 className="text-center">Image uploaded by creator</h1>
                  </div>

                  {/* image uploaded by opponents */}
                  <div>
                    <img
                      onClick={() => {
                        setImgIsOpen(true);
                        setImgUrl(ludo.opponentImage);
                      }}
                      className="w-[200px] mx-auto"
                      src={ludo.opponentImage}
                    ></img>
                    <h1 className="text-center">Image uploaded by Opponent</h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="flex justify-between text-xl text-white  items-baseline bg-blue-500 p-2">
              <h2 className="font-bold">Result Declarations</h2>
              <button
                className="bg-red-500 px-3 w-20   py-1 rounded-full"
                onClick={closeModal}
              >
                close
              </button>
            </div>
            <div className="flex justify-center items-center">
              <form className="py-10">
                <select
                  className="ml-4 min-w-[15rem] rounded-lg focus:border-blue border-2 p-2 ring-2 hover:ring-red-500 border-solid text-zinc-400"
                  onChange={(event) => setWinner(event.target.value)}
                  value={winner}
                >
                  <option value={null}>Select Winner</option>
                  <option value="creator">Creator</option>
                  <option value="opponent">Opponent</option>
                </select>

                <button
                  onClick={declareWinner}
                  className="bg-red-500 text-white rounded-lg ml-4 p-2 ring-2"
                >
                  Submit
                </button>
              </form>
            </div>
          </Modal>
        </div>

        <Modal
          isOpen={imgIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setImgIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div class=" rounded-lg md:max-w-lg max-h-[80%] overflow-auto bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
            <div className="text-right m-5">
              <button
                className="ring bg-yellow-400"
                onClick={() => setImgIsOpen(false)}
              >
                ❌
              </button>
            </div>
            <div className="w-full">
              <img src={imgUrl} className="w-full"></img>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        isOpen={cancelLudoOpen}
        onRequestClose={() => setCancelLudoOpen(false)}
        style={customStyles}
      >
        <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
          <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            Confirm Action
          </h3>
          <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

          <p class="mb-10">
            Are you sure you want to cancel the challenge? After cancellation,
            the amount will be refunded to participants.
          </p>
          <div class="-mx-3 flex flex-wrap gap-y-4">
            <div class="w-1/2  px-3">
              <button
                onClick={() => {
                  setCancelLudoOpen(false);
                  cancelLudoChallenge();
                }}
                class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Yes
              </button>
            </div>
            <div class="w-1/2  px-3">
              <button
                onClick={() => setCancelLudoOpen(false)}
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default LudoChallengeById;
