import axios from "axios";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import Card1 from "../../components/Card1";

function AddUpdates({ closeModal }) {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [file, setFile] = useState();
  const [version, setVersion] = useState("");
  const [description, setDescription] = useState("");
  const [reRender, setReRender] = useState(false);
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!file) {
      return alert("Please select a file");
    }
    if (!version) {
      return alert("Versions is required");
    }
    if (!description) {
      return alert("Description is required");
    }

    // Create FormData object

    const formData = new FormData();
    formData.append("file", file);
    formData.append("version", version);
    formData.append("description", description);

    try {
      setIsloading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/updates`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.responseStatusCode === 403) {
        return navigate("/login");
      }
      alert(response.data.responseMessage);
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <form
      onSubmit={submitHandler}
      className="flex flex-col items-start min-w-96 text-lg  max-w-xl bg-white dark:bg-boxdark rounded-lg border border-stroke px-8 py-8  dark:text-white md:py-15"
    >
      <div className="">
        <div className="flex flex-col">
          <label for="file" className="mt-4 text-zinc-700 font-bold">
            Select file:
          </label>
          <input
            id="file"
            type="file"
            style={{ textShadow: "1px 1px 1px black" }}
            onChange={(e) => setFile(e.target.files[0])}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
          <label className="mt-4 text-zinc-700 font-bold" for="version">
            Version:
          </label>
          <input
            type="text"
            id="version"
            style={{ textShadow: "1px 1px 1px black" }}
            onChange={(e) => setVersion(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
          />
          <label for="description" className="mt-4 text-zinc-700 font-bold">
            Description:
          </label>
          <textarea
            id="description"
            style={{ textShadow: "1px 1px 1px black" }}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
            rows="4"
            cols="50"
          ></textarea>
          <div class="-mx-3 mt-10 flex flex-wrap ">
            <div class="w-1/2  px-3">
              <button
                type="submit"
                className={`block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90 ${
                  isLoading ? "hidden" : ""
                }`}
              >
                {isLoading ? "wait" : "Upload"}
              </button>
            </div>
            <div class="w-1/2  px-3">
              <button
                onClick={closeModal}
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddUpdates;
