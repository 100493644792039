import React from "react";

function PlayersCards({ player, i }) {
  return (
    <div
      href="#"
      class="flex flex-col items-center bg-white border border-stroke rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <img
        class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
        src={player.profileImage}
        alt=""
      />
      <div class="flex flex-col justify-between p-4 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Player {i + 1}:
        </h5>
        <div>
          <div>
            <span className="capitalize font-extrabold mr-3">
              In-game User Name:
            </span>
            {player.ingameUserName}
          </div>
          <div>
            <span className="capitalize font-extrabold mr-3">rank:</span>
            {player.rank}
          </div>
          <div>
            <span className="capitalize font-extrabold mr-3">userName:</span>
            {player.userName}
          </div>
          <div>
            <span className="capitalize font-extrabold mr-3">user Id:</span>
            {player.userId}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayersCards;

{
  /* <ul>
              {team.teamPlayers.map((el, i) => {
                return (
                  <li key={el.userId}>
                    <div className="p-10">
                      <div className="bg-green-500 px-5 font-bold text-white">
                        player {i + 1}:
                      </div>
                      <div className="flex p-5 gap-10">
                        <div>
                          <img
                            className="w-32 h-32"
                            src={el.profileImage}
                          ></img>
                        </div>
                        <div>
                          <div>user Id:{el.userId}</div>
                          <div>ingameUserName :{el.ingameUserName}</div>
                          <div>rank:{el.rank}</div>
                          <div>userName:{el.userName}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul> */
}
