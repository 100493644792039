import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Card1 from "../../components/Card1";
import Modal from "react-modal";
import ReferralSettingsUpdate from "./ReferralSettingsUpdate";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function ReferralSettings() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/referrel`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.referrelData);
        setIsloading(false);
      })
      .catch((error) => navigate("/error"));
  }, []);

  return (
    <Card1>
      <h3 className="text-2xl capitalize whitespace-nowrap text-start  font-semibold text-black dark:text-white">
        Referral Information
      </h3>

      <div className="border-b border-stroke py-2 mb-6 px-6.5 dark:border-strokedark"></div>

      <div className="mb-8">
        <button
          onClick={() => {
            openModal();
          }}
          className="inline-flex text-sm items-center justify-center bg-danger   py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
        >
          Update Values
        </button>
      </div>

      {isLoading ? (
        <Loader></Loader>
      ) : (
        <div className="border border-stroke">
          <table className="w-full table-auto">
            <tbody>
              <tr>
                <th className="py-4 px-4 whitespace-nowrap text-start border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  Referrer Amount:
                </th>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {apiData.referrerAmount}
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <span className="font-bold"> Note:</span> The referrer will
                  receive this sum when any user creates an account using the
                  referrer's unique code.
                </td>
              </tr>
              <tr>
                <th className="py-4 px-4 whitespace-nowrap text-start border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  User Amount:
                </th>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {apiData.userAmount}
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <span className="font-bold"> Note:</span> A new user will
                  receive this sum upon signing up with someone else's referral
                  code.
                </td>
              </tr>

              <tr>
                <th className="py-4 px-4 whitespace-nowrap text-start border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  Last Updated At:
                </th>
                <td className="border-b whitespace-nowrap border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {format(apiData.updatedAt, "📆dd-MM-yyyy  🕐k:m:s")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ReferralSettingsUpdate
          closeModal={closeModal}
          documentId={apiData._id}
        />
      </Modal>
    </Card1>
  );
}

export default ReferralSettings;
