import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Card1 from "../components/Card1";
import Loader from "../components/Loader";

function Contacts() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [searchBy, setSearchBy] = useState("");

  const [transactionType, setTransactionType] = useState("");
  const [gameName, setGameName] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/admin/contacts?skip=${
            (currentPage - 1) * dataPerPage
          }&limit=${dataPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.contacts);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, [currentPage, searchBy, transactionType, gameName]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1 title={"Users Queries"}>
      <div className="min-h-screen flex flex-col  justify-between">
        <div className="flex  justify-evenly py-2"></div>
        <div>
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <div id="parentScrollDiv" className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="border-b border-stone-300">
                  <tr className="bg-gray-2 shadow-inner text-left dark:bg-meta-4">
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Sr. No.
                    </th>
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      User Name
                    </th>
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Phone Number
                    </th>
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Email
                    </th>
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Query Type
                    </th>
                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Description
                    </th>

                    <th className=" px-4 font-bold py-4 whitespace-nowrap  text-black dark:text-white xl:pl-11">
                      Date and Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((el, i) => (
                    <tr key={el._id} className="hover:bg-gray-2">
                      <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {i + 1 + (currentPage - 1) * dataPerPage}
                      </td>
                      <td
                        onClick={() =>
                          navigate(`/users/${el.userId.id}/personalInfo`)
                        }
                        className="border-b border-[#eee] whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11 hover:underline cursor-pointer text-blue-500 capitalize"
                      >
                        {el.userId?.name || ""}
                      </td>
                      <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {el.userId?.mobileNo || ""}
                      </td>
                      <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {el.userId?.email || ""}
                      </td>
                      <td className="border-b whitespace-nowrap border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {el.queryType}
                      </td>
                      <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {el.descreption}
                      </td>
                      <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
          <nav className="flex flex-wrap items-center">
            {/* Previous page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={prevPageHandler}
            >
              {"<"}
            </span>
            {/* Render page numbers */}
            {renderPages()}
            {/* Next page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={nextPageHandler}
            >
              {">"}
            </span>
          </nav>
        </div>
      </div>
    </Card1>
  );
}

export default Contacts;
