import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./components/Header";
import SideNavbar from "./components/SideNavbar";

function ProtectedRoutes() {
  const [navOpen, setNavOpen] = useState(true);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const isLocalAuth = localStorage.getItem("kings_club_admin_token");
  const isSessionAuth = sessionStorage.getItem("kings_club_admin_token");
  if (isSessionAuth || isLocalAuth) {
    return (
      <>
        <div className="lg:hidden block">
          <Header toggleNav={toggleNav} />
        </div>
        <div className="flex">
          <div
            className={`${
              navOpen ? "" : "transform -translate-x-full"
            } absolute z-9999 duration-300 lg:relative lg:translate-x-0 lg:block`}
          >
            <SideNavbar toggleNav={toggleNav} />
          </div>

          <div className="w-full h-screen overflow-x-auto bg-[#f1f5f9]  mr-1">
            <div className="hidden lg:block">
              <Header toggleNav={toggleNav} />
            </div>
            <div className="md:p-1 lg:p-2 xl:p-4 p-[1px]">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  } else {
  }
  return <Navigate to="/login" />;
}

export default ProtectedRoutes;
