import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { data } from "../../Data";
import Card1 from "../../components/Card1";
function UpdateGames() {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [game, setGame] = useState({});
  const [gameName, setGameName] = useState("");
  const [type, setType] = useState("");
  const [rule, setRule] = useState("");
  const [logo, setLogo] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [formData, setFormData] = useState({});

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/games/${gameId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setFormData({
          gameName: response.data.responseData.game.gameName,
          type: response.data.responseData.game.type,
          rule: response.data.responseData.game.rule,
          status: true,
          logo: response.data.responseData.game.logo,
        });
        setIsloading(false);
      })
      .catch((error) => alert("Something went wrong"));
  }, []);

  useState(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/games/${gameId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setGame(response.data.responseData.game);
        setIsloading(false);
      });
  }, [formData]);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/games/${gameId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate(`/games`);
    } catch (error) {
      alert("Something went wrong please try later");
    }
  };

  const uploadImage = async (e) => {
    const imageFile = e.target.files[0]; // Retrieve the file from the event

    const formData = new FormData(); // Create FormData object
    formData.append("image", imageFile); // Append the image file with the correct field name

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setImageUrl(response.data.responseData.data.imgUrl);
      setFormData({ logo: response.data.responseData.data.imgUrl });
    } catch (error) {
      alert("Something went wrong");
    }
  };
  return (
    <Card1 title={"Update Game"}>
      <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
        Update game
      </div>
      <form onSubmit={submitHandler} className="">
        <div className="border border-stroke p-6.5">
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="flex-1">
              <img
                src={formData.logo}
                alt="banner"
                className="min-h-[200px] w-full max-w-sm mx-auto ring-stroke ring-8 m-5 mt-2"
              ></img>
            </div>

            <div className="flex-1">
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="gameName"
                  >
                    Game Name
                  </label>
                  <input
                    type="text"
                    value={formData.gameName}
                    name="gameName"
                    id="gameName"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="type">
                    Type
                  </label>
                  <input
                    type="text"
                    name="type"
                    value={formData.type}
                    id="type"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="rule">
                    Rule
                  </label>

                  <input
                    type="text"
                    value={formData.rule}
                    name="rule"
                    id="rule"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="logo">
                    logo
                  </label>
                  <input
                    type="file"
                    name="logo"
                    id="logo"
                    // value={}
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    onChange={(e) => uploadImage(e)}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md bg-primary py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </Card1>
  );
}

export default UpdateGames;
