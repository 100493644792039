import axios from "axios";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Card1 from "../../components/Card1";

function AddNewTournaments() {
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const navigate = useNavigate();
  const [err, setErr] = useState({});
  const [games, setGames] = useState([]);
  const [formData, setFormData] = useState({
    gameName: "",
    title: "",
    description: "",
    adminShare: "",
    challengeType: 0,
    mode: "",
    map: "",
    entryFee: 0,
    coinPerkill: 0,
    prizeAmount: 0,
    startTime: "",
    totalParticipants: 0,
    // prizePool: [],
    uploadBanner: "",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/admin/games`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setGames(response.data.responseData.games);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.title && !formData.title.trim()) {
      return setErr({ title: "A valid title is required" });
    }

    if (formData.gameName && !formData.gameName.trim()) {
      return setErr({ gameName: "A valid game name is required" });
    }

    if (formData.description && !formData.description.trim()) {
      return setErr({ description: "A valid description is required" });
    }

    if (formData.adminShare && !formData.adminShare.trim()) {
      return setErr({ adminShare: "A valid admin share is required" });
    }

    if (!formData.challengeType) {
      return setErr({ challengeType: "A valid challengeType is required" });
    }

    if (formData.mode && !formData.mode.trim()) {
      return setErr({ mode: "A valid mode is required" });
    }

    if (formData.map && !formData.map.trim()) {
      return setErr({ map: "A valid map is required" });
    }

    if (!formData.entryFee) {
      return setErr({ entryFee: "A valid entryFee is required" });
    }

    if (!formData.coinPerkill) {
      return setErr({ coinPerkill: "A valid coin per kill is required" });
    }

    if (!formData.totalParticipants) {
      return setErr({ totalParticipants: "A valid data is required" });
    }
    if (!formData.startTime) {
      return setErr({ startTime: "A valid data is required" });
    }
    if (!formData.prizeAmount) {
      return setErr({ prizeAmount: "A valid data is required" });
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        navigate("/tournaments");
      })
      .catch((error) => console.log("error", error));
  };
  const uploadImg = (e) => {
    const selectedFile = e.target.files[0];

    // setFile(selectedFile);

    const imgformData = new FormData();
    imgformData.append("image", selectedFile);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/v1/admin/images`, imgformData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setFormData({
          ...formData,
          uploadBanner: response.data.responseData.data.imgUrl,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <Card1 title={"Add New Tournament"}>
      <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
        Add new tournament
      </div>
      <form onSubmit={handleSubmit}>
        <div className="border border-stroke p-6.5">
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="flex-1">
              <img
                src={formData.uploadBanner}
                alt="banner"
                className="min-h-[200px] w-full max-w-sm mx-auto ring-8 ring-stroke m-7"
              ></img>
            </div>
            <div className="flex-1">
              {/* upload image */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="banner">
                    Select Banner
                  </label>
                  <input
                    id="banner"
                    onChange={(e) => uploadImg(e)}
                    type="file"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  ></input>
                </div>
              </div>
              {/* Title */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="title">
                    Title
                  </label>
                  <input
                    id="title"
                    name="title"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  ${
                      err.title ? "ring-2 ring-red-500" : ""
                    }`}
                    type="text"
                    placeholder={"Title"}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Select game */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="game">
                    Select Game
                  </label>
                  <select
                    onChange={handleChange}
                    name="gameName"
                    id="game"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  ${
                      err.gameName ? "ring-2 ring-red-500" : ""
                    }`}
                  >
                    {games.map((game) => (
                      <option value={game.gameName}>{game.gameName}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* starts at*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="startsAt"
                  >
                    Starts at
                  </label>
                  <input
                    id="startsAt"
                    name="startTime"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  ${
                      err.title ? "ring-2 ring-red-500" : ""
                    }`}
                    type="datetime-local"
                    placeholder={"startsAt"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Chalenge type*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="challengeType"
                  >
                    Challenge Type
                  </label>
                  <select
                    onChange={handleChange}
                    name="challengeType"
                    id="challengeType"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  ${
                      err.challengeType ? "ring-2 ring-red-500" : ""
                    }`}
                  >
                    <option value={1}>Solo</option>
                    <option value={2}>Duo</option>
                    <option value={3}>Squad</option>
                  </select>
                </div>
              </div>

              {/* Mode*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="mode">
                    mode
                  </label>
                  <input
                    id="mode"
                    name="mode"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary  ${
                      err.mode ? "ring-2 ring-red-500" : ""
                    }`}
                    type="text"
                    placeholder={"mode"}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* 4 */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="adminShare"
                  >
                    Admin Share
                  </label>
                  <input
                    id="adminShare"
                    name="adminShare"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.adminShare ? "ring-2 ring-red-500" : ""
                    }`}
                    type="number"
                    placeholder={5}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* prize amount */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="prizeAmount"
                  >
                    Prize Amount
                  </label>
                  <input
                    id="prizeAmount"
                    name="prizeAmount"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.prizeAmount ? "ring-2 ring-red-500" : ""
                    }`}
                    type="number"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* map */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="map">
                    Map
                  </label>
                  <input
                    id="map"
                    name="map"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.map ? "ring-2 ring-red-500" : ""
                    }`}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* points per kill */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="coinPerkill"
                  >
                    Points/Kill
                  </label>
                  <input
                    id="coinPerkill"
                    name="coinPerkill"
                    type="text"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.coinPerkill ? "ring-2 ring-red-500" : ""
                    }`}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Entry fee */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="entryFee"
                  >
                    Entry Fee
                  </label>
                  <input
                    id="entryFee"
                    name="entryFee"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.entryFee ? "ring-2 ring-red-500" : ""
                    }`}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* total participants*/}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="totalParticipants"
                  >
                    Total Participants
                  </label>
                  <input
                    id="totalParticipants"
                    name="totalParticipants"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.totalParticipants ? "ring-2 ring-red-500" : ""
                    }`}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* description */}
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="description"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    className={`w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary ${
                      err.description ? "ring-2 ring-red-500" : ""
                    }`}
                    placeholder="text here....."
                    rows={7}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
          <button
            type="submit"
            className="inline-flex items-center justify-center rounded-md bg-primary py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
          >
            Submit
          </button>
        </div>
      </form>
    </Card1>
  );
}

export default AddNewTournaments;
