import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Card1 from "../../components/Card1";
import Modal from "react-modal";
import DeductionUpdate from "./DeductionUpdate";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function Deduction() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/deductions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData);
        setIsloading(false);
      })
      .catch((error) => navigate("/error"));
  }, []);

  return (
    <Card1>
      <h3 className="text-2xl capitalize whitespace-nowrap text-start  font-semibold text-black dark:text-white">
        Deductions Information
      </h3>

      <div className="border-b border-stroke py-2 mb-6 px-6.5 dark:border-strokedark"></div>

      <div className="mb-8">
        <button
          onClick={() => setIsOpen(true)}
          className="inline-flex text-sm items-center justify-center bg-danger   py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
        >
          Update Values
        </button>
      </div>

      <div className="flex justify-center gap-10">
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <table className="w-full table-auto border border-stroke">
            <tbody>
              <tr>
                <th className="whitespace-nowrap text-start border-r py-4 px-4 border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  Deduct Bonus Amount:
                </th>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {apiData.deductAmount}
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <span className="font-bold"> Note:</span> This represents the
                  maximum percentage of coins that users can utilize from their
                  bonus wallet when creating or joining a challenge
                </td>
              </tr>
              <tr>
                <th className="whitespace-nowrap text-start border-r py-4 px-4 border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  Referer Share:
                </th>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {apiData.referrShare}
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <span className="font-bold"> Note:</span> If someone wins the
                  Ludo challenge, a part of the prize money will go to the
                  person who referred the winner.
                </td>
              </tr>

              <tr>
                <th className="whitespace-nowrap text-start border-r py-4 px-4 border-b border-[#eee] font-medium text-black dark:text-white xl:pl-11">
                  Last Updated At:
                </th>
                <td className="whitespace-nowrap text-start  border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  {format(apiData.updatedAt, "📆dd-MM-yyyy  🕐k:m:s")}
                </td>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11"></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <DeductionUpdate closeModal={closeModal} />
      </Modal>
    </Card1>
  );
}

export default Deduction;
