import React from "react";
import {
  FaDesktop,
  FaUsers,
  FaDice,
  FaPercent,
  FaGamepad,
  FaFlagCheckered,
  FaRegCreditCard,
  FaWallet,
  FaEnvelopeOpenText,
  FaGears,
  FaGooglePlay,
} from "react-icons/fa6";
import { CiLogout } from "react-icons/ci";
import { NavLink, useLocation } from "react-router-dom";

function SideNavbar({ toggleNav }) {
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col min-w-[275px] items-center justify-start p-4 py-10 bg-black h-screen overflow-y-hidden">
      <div className="flex text-3xl w-full p-2 px-4 items-center justify-start lg:text-4xl  font-bold gap-5 mx-auto  text-white  rounded-lg whitespace-nowrap">
        <img
          className="w-16 h-16 rounded-full ring-8 ring-blue-500"
          src="https://kings-club.s3.ap-south-1.amazonaws.com/1707822342187_image_kingsclub%20logo.jpg"
        ></img>

        <div className="underline">Admin</div>
      </div>

      <ul className="xl:mt-10 lg:mt-8 md:mt-6 sm:mt-4 w-full tracking-wide   overflow-y-auto your-container-class">
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black  gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              (pathname.split("/")[1] == "dashboard" ||
                !pathname.split("/")[1]) &&
              "bg-blue-500 shadow-none border border-blue-700 "
            }`}
          >
            <FaDesktop />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/dashboard"}
              className={`active hover:tracking-wider `}
            >
              Dashboard
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70  text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "users" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaUsers />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/users"}
              className="hover:tracking-wider"
            >
              Users
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "ludo" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaDice />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/ludo"}
              className="hover:tracking-wider "
            >
              Ludo
            </NavLink>
          </div>
        </li>

        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "tournaments" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaFlagCheckered />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/tournaments"}
              className="hover:tracking-wider"
            >
              Tournament
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "transactions" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaRegCreditCard />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/transactions"}
              className="hover:tracking-wider"
            >
              Transactions
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "withdrawalRequests" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaWallet />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/withdrawalRequests"}
              className="hover:tracking-wider"
            >
              Withdrawal
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "games" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaGamepad />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/games"}
              className="hover:tracking-wider"
            >
              Game
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "contacts" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaEnvelopeOpenText />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/contacts"}
              className="hover:tracking-wider"
            >
              Contact
            </NavLink>
          </div>
        </li>

        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "updates" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaGooglePlay />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/updates"}
              className="hover:tracking-wider"
            >
              Updates
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "deduction" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaPercent />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/deduction"}
              className="hover:tracking-wider"
            >
              Deduction
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl ${
              pathname.split("/")[1] == "settings" &&
              "bg-blue-500 shadow-none border border-blue-700  rounded text-white"
            }`}
          >
            <FaGears />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={toggleNav}
              to={"/settings"}
              className="hover:tracking-wider"
            >
              Settings
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className={`flex items-center border border-stone-700 border-opacity-70 text-white  shadow-sm shadow-black gap-3 hover:gap-4 transition-all hover:bg-blue-500 rounded   p-1 px-3 duration-200 my-3 cursor-pointer text-xl`}
          >
            <CiLogout />
            <NavLink
              // style={{ textShadow: "0px 0px 1px #ffffff" }}
              onClick={() => {
                sessionStorage.setItem("kings_club_admin_token", "");
                localStorage.setItem("kings_club_admin_token", "");
              }}
              to={"/login"}
              className="hover:tracking-wider"
            >
              Logout
            </NavLink>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SideNavbar;
