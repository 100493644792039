import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import UsersNav from "./UsersNav";
import { format } from "date-fns";
import Card1 from "../../components/Card1";
import Loader from "../../components/Loader";

const UserTransactions = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [trxns, setTrxns] = useState({});
  const [transactionType, setTransactionType] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const [isLoading, setIsloading] = useState(true);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/v1/admin/users/${userId}/transactions?status=${transactionStatus}&type=${transactionType}&skip=${
          (currentPage - 1) * 10
        }&limit=${dataPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setTrxns(response.data.responseData.transactions);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, [transactionType, transactionStatus, currentPage]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1>
      <div className="flex flex-col w-full">
        <UsersNav userId={userId} />
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div>
              <div className="w-full  pb-4 flex flex-col md:flex-row  md:justify-between md:items-center  ">
                <div>
                  <select
                    className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    onChange={(event) => setTransactionType(event.target.value)}
                    value={transactionType}
                  >
                    <option value="">All</option>
                    <option value="deposit">Deposit</option>
                    <option value="win">Winning</option>
                    <option value="withdraw">Withdrawn</option>
                    <option value="challenge">Ludo Challenges</option>
                    <option value="admin_credit">Deposited By Admin</option>
                    <option value="admin_debit">Deducted By Admin</option>
                  </select>
                </div>
              </div>
              <div>
                {isLoading ? (
                  <Loader></Loader>
                ) : (
                  <div className="max-w-full  overflow-x-auto">
                    <table className="w-full table-auto">
                      <thead className="border-b border-stone-300">
                        <tr className="bg-gray shadow-inner  text-left dark:bg-meta-4">
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white ">
                            Sr.
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Amount
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Game Name
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Transaction Id
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Credit
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Debit
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Transaction Type
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Date and Time
                          </th>
                          <th className="whitespace-nowrap   p-4 font-bold  text-black dark:text-white">
                            Transaction Message
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {trxns.map((el, i) => (
                          <tr key={el._id} className="hover:bg-gray-2">
                            <td className="border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark">
                              {i + 1 + (currentPage - 1) * dataPerPage}
                            </td>
                            <td className="border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {el.amount}
                            </td>

                            <td className="capitalize border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {el.gameType}
                            </td>
                            <td className="border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {el._id}
                            </td>
                            <td className="text-center border-b border-[#eee]  bg-green-100  whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                              {" "}
                              {el.transactionType === "win" ||
                              el.transactionType === "deposit" ||
                              el.transactionType === "bonus" ||
                              el.transactionType === "refund" ||
                              el.transactionType === "referal" ||
                              el.transactionType === "admin_credit" ||
                              el.transactionType === "admin"
                                ? el.amount
                                : null}
                            </td>
                            <td className="text-center border-b border-[#eee]  bg-red-100  whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                              {" "}
                              {el.transactionType === "withdraw" ||
                              el.transactionType === "lost" ||
                              el.transactionType === "admin_debit" ||
                              el.transactionType === "challenge"
                                ? el.amount
                                : null}
                            </td>
                            <td className="capitalize text-center border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {el.transactionType}
                            </td>
                            <td className="whitespace-nowrap border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                            </td>
                            <td className="border-b border-[#eee]  text-sm  px-1 pr-4  dark:border-stone-300dark ">
                              {el.transactionDetail.message}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
                <nav className="flex flex-wrap items-center">
                  {/* Previous page button */}
                  <span
                    className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
                    onClick={prevPageHandler}
                  >
                    {"<"}
                  </span>
                  {/* Render page numbers */}
                  {renderPages()}
                  {/* Next page button */}
                  <span
                    className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
                    onClick={nextPageHandler}
                  >
                    {">"}
                  </span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card1>
  );
};

export default UserTransactions;
