import React, { useEffect, useState } from "react";
import axios from "axios";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { MdDelete } from "react-icons/md";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import Card1 from "../../components/Card1";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function Games() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [searchBy, setSearchBy] = useState("");

  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [gameId, setGameId] = useState("");
  const [reRender, setReRender] = useState(false);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    const getData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/v1/admin/games?search=${searchBy}&skip=${
            (currentPage - 1) * dataPerPage
          }&limit=${dataPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.games);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, [currentPage, searchBy, reRender]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const deleteGame = async () => {
    try {
      setIsloading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/games/${gameId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsloading(false);

      alert(response.data.responseMessage);
      setReRender(!reRender);
    } catch (error) {
      console.log({ error });
    }
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1 title={"Active games"}>
      <div className="max-w-full  overflow-x-auto your-container-class">
        <div className="flex  justify-start pb-5 my-5">
          <input
            type="text"
            className="form-datepicker max-w-70 w-full rounded border-[1.5px] border-stroke bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="search by name"
            onChange={(e) =>
              setSearchBy(e.target.value.replace(/([.?*+^$[\]\\(){}|-])/g, ""))
            }
          />
          <button
            onClick={() => navigate(`/games/addNewGame`)}
            className="inline-flex text-sm items-center justify-center bg-black-2 ml-4 whitespace-nowrap   py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
          >
            Add new game
          </button>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="max-w-full overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="border-b border-stone-300">
                <tr className="bg-gray shadow-inner text-left dark:bg-meta-4">
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Sr. No.
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Logo
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Name
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Type
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Rule
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Status
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiData.map((el, i) => (
                  <tr key={el._id}>
                    <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {i + 1 + (currentPage - 1) * dataPerPage}
                    </td>
                    <td className="border-b border-[#eee]    dark:border-strokedark ">
                      <img src={el.logo} className="w-10 h-10" />
                    </td>
                    <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.gameName}
                    </td>
                    <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.type}
                    </td>
                    <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.rule}
                    </td>
                    <td className="border-b border-[#eee]  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {`${el.status}`}
                    </td>
                    <td className="border-b text-center  border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                      <div className="flex gap-2 justify-center">
                        <Tippy content="Delete">
                          <button
                            onClick={() => {
                              setGameId(el._id);
                              setIsOpen(true);
                            }}
                            className="inline-flex text-sm items-center justify-center bg-danger py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                          >
                            <MdDelete />
                          </button>
                        </Tippy>

                        <Tippy content="Update">
                          <button
                            onClick={() => navigate(`/games/${el._id}/update`)}
                            className="inline-flex text-sm items-center justify-center bg-yellow-500 py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                          >
                            <IoMdSettings />
                          </button>
                        </Tippy>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
        <nav className="flex flex-wrap items-center">
          {/* Previous page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={prevPageHandler}
          >
            {"<"}
          </span>
          {/* Render page numbers */}
          {renderPages()}
          {/* Next page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={nextPageHandler}
          >
            {">"}
          </span>
        </nav>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
          <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
            Confirm Action
          </h3>
          <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

          <p class="mb-10">
            Are you sure you want to delete this game? After deletion, the game
            will no longer available on the app
          </p>
          <div class="-mx-3 flex flex-wrap gap-y-4">
            <div class="w-1/2  px-3">
              <button
                onClick={() => {
                  deleteGame(gameId);
                  setIsOpen(false);
                }}
                class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
              >
                Yes
              </button>
            </div>
            <div class="w-1/2  px-3">
              <button
                onClick={() => setIsOpen(false)}
                class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Card1>
  );
}

export default Games;
