import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import UsersNav from "./UsersNav";
import Modal from "react-modal";
import AddAmountTouserWallet from "./AddAmountTouserWallet";
import DeductAmountFromUserWallet from "./DeductAmountFromUserWallet";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";
function UserWalletInformation() {
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  const navigate = useNavigate();

  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deductIsOpen, setDeductIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setUser(response.data.responseData.user);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div class="rounded-sm border min-h-screen border-stroke bg-white md:p-7.5 p-3 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col w-full">
        <UsersNav userId={userId} />

        <div>
          <div className="space-x-2 md:space-x-4">
            <button
              onClick={openModal}
              className="inline-flex items-center w-35 justify-center text-sm bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              Add Amount
            </button>
            <button
              onClick={() => setDeductIsOpen(true)}
              className="inline-flex items-center w-35 justify-center text-sm bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              Deduct Amount
            </button>
          </div>
          <div className="flex gap-5 w-full">
            <div className="flex flex-col w-full">
              <div>
                <div className="py-8">
                  {isLoading ? (
                    <Loader></Loader>
                  ) : (
                    <table className="w-full border border-stroke">
                      <tbody>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2 w-1/2">
                            Total Cash Amount:
                          </th>
                          <td className="">
                            {(
                              user.wallet.totalAmountDeposited +
                              user.wallet.totalAmountWon +
                              user.wallet.myBonus
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2">
                            Total Amount Deposited:
                          </th>
                          <td className="">
                            {user.wallet.totalAmountDeposited.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2">Total Amount Won:</th>
                          <td className="">
                            {user.wallet.totalAmountWon.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2">
                            Total Amount Withdrawn:
                          </th>
                          <td className="">
                            {user.wallet.totalAmountWithdrawn.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2">My Bonus:</th>
                          <td className="">{user.wallet.myBonus.toFixed(2)}</td>
                        </tr>
                        <tr className="border-b border-[#eee]  hover:bg-gray-2 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <th className="text-start p-2">
                            Total Amount By Reference:
                          </th>
                          <td className="">
                            {user.wallet.totalAmountByReferAndEarn.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <AddAmountTouserWallet closeModal={closeModal} />
                </Modal>
                <Modal
                  isOpen={deductIsOpen}
                  onRequestClose={() => setDeductIsOpen(false)}
                  style={customStyles}
                >
                  <DeductAmountFromUserWallet
                    setDeductIsOpen={setDeductIsOpen}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserWalletInformation;
