import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import Card1 from "../../components/Card1";

function ViewChats() {
  const { ludoId } = useParams();
  const navigate = useNavigate();
  const [chat, setChat] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/ludo/${ludoId}/chats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setChat(response.data.responseData.data);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);
  // 658173225abd7f2bf12be39b
  return (
    <Card1>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        <div className="flex items-center justify-center md:p-20">
          <ul className="w-[30rem] border border-stroke min-h-screen p-2 md:p-10 rounded-lg">
            {chat.chat.length == 0 ? (
              <h1>No Chat found</h1>
            ) : (
              chat.chat.map((el, index) => (
                <li
                  className={` flex  py-4 ${
                    el._fieldsProto.idFrom.stringValue ==
                    chat.players.creator.uid
                      ? "justify-end"
                      : "justify-start"
                  }`}
                  key={index}
                >
                  <div className="max-w-[70%]">
                    <p
                      className={`text-xs text-strokedark ${
                        el._fieldsProto.idFrom.stringValue ==
                        chat.players.creator.uid
                          ? "text-end"
                          : "text-start"
                      }`}
                    >
                      {el._fieldsProto.idFrom.stringValue ==
                      chat.players.creator.uid
                        ? "Creator"
                        : "Opponent"}
                    </p>
                    <div
                      className={`mb-2.5 rounded-2xl  bg-gray py-3 px-5 dark:bg-boxdark-2 ${
                        el._fieldsProto.idFrom.stringValue ==
                        chat.players.creator.uid
                          ? "rounded-tr-none"
                          : "rounded-tl-none"
                      }`}
                    >
                      <p>{el._fieldsProto.content.stringValue}</p>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </Card1>
  );
}

export default ViewChats;
