import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

function UsersNav({ userId }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/").pop();

  return (
    <div class="mb-6 flex flex-wrap gap-5   border-b border-stroke  dark:border-strokedark sm:gap-10">
      <button
        onClick={() => navigate(`/users/${userId}/personalInfo`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "personalInfo" ? "underline" : "text-zinc-700 "
        }`}
      >
        Personal Info
      </button>
      <button
        onClick={() => navigate(`/users/${userId}/wallet`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "wallet" ? "underline" : "text-zinc-700 "
        }`}
      >
        Wallet Info
      </button>

      <button
        onClick={() => navigate(`/users/${userId}/otherInfo`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "otherInfo" ? "underline" : "text-zinc-700 "
        }`}
      >
        Other Info
      </button>

      <button
        onClick={() => navigate(`/users/${userId}/tansactions`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "tansactions" ? "underline" : "text-zinc-700 "
        }`}
      >
        Transactions
      </button>

      <button
        onClick={() => navigate(`/users/${userId}/ludo`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "ludo" ? "underline" : "text-zinc-700 "
        }`}
      >
        Ludo challenges
      </button>
      <button
        onClick={() => navigate(`/users/${userId}/tournaments`)}
        class={`border-b-2 py-4 text-sm font-medium hover:text-primary md:text-base border-transparent ${
          path === "tournaments" ? "underline" : "text-zinc-700 "
        }`}
      >
        Tournaments
      </button>
    </div>
  );
}

export default UsersNav;
