import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaUsers,
  FaDice,
  FaGamepad,
  FaFlagCheckered,
  FaWallet,
} from "react-icons/fa6";
import Card1 from "../components/Card1";

function Home() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [userInfoLoading, setUserInfoLoading] = useState(true);
  const [gamesInfo, setGamesInfo] = useState(0);
  const [gamesInfoLoading, setGamesInfoLoading] = useState(true);
  const [withdrawalInfo, setWithdrawalInfo] = useState({});
  const [withdrawalInfoLoading, setWithdrawalInfoLoading] = useState({});
  const [ludoInfo, setLudoInfo] = useState({});
  const [ludoInfoLoading, setLudoInfoLoading] = useState({});
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [tournamentInfoLoading, setTournamentInfoLoading] = useState({});
  const [depositsByUser, setDepositsByUsers] = useState({});
  const [depositsByUserLoading, setDepositsByUsersLoading] = useState({});
  const [depositsByAdmin, setDepositsByAdmin] = useState({});
  const [depositsByAdminLoading, setDepositsByAdminLoading] = useState({});
  const [time, setTime] = useState("");

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  // users
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/usersInfo?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setUserInfo(response.data.responseData);
        setUserInfoLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);

  // games
  useEffect(() => {
    setUserInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/gamesCount?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setGamesInfo(response.data.responseData);
        setGamesInfoLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);

  // Withdrawal
  useEffect(() => {
    setWithdrawalInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/totalWithdrawal?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setWithdrawalInfo(response.data.responseData);
        setWithdrawalInfoLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);

  // Ludo
  useEffect(() => {
    setUserInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/ludoChallengeInfo?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setLudoInfo(response.data.responseData.ludoChallengeInfo);
        setLudoInfoLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);

  // deposits by user
  useEffect(() => {
    setUserInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/amountDepositedByUsers?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setDepositsByUsers(response.data.responseData.totalSum);
        setDepositsByUsersLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);

  // deposits by Admin
  useEffect(() => {
    setUserInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/amountDepositedByAdmin?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setDepositsByAdmin(response.data.responseData.totalSum);
        setDepositsByAdminLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);
  // Tournaments
  useEffect(() => {
    setTournamentInfoLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/dashboard/tournamentInfo?time=${time}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setTournamentInfo(response.data.responseData.tournamentInfo);
        setTournamentInfoLoading(false);
      })
      .catch((error) => {
        return navigate("/error");
      });
  }, [time]);
  return (
    <Card1 title={"Dashboard"}>
      <div className="mx-auto max-w-screen-2xl  ">
        <div className="flex  justify-start pb-5 my-5 gap-10">
          <select
            className="form-datepicker max-w-70 w-full rounded border-[1.5px] border-stroke bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            onChange={(event) => setTime(event.target.value)}
            value={time}
          >
            <option value>Select Time</option>
            <option value="">All</option>
            <option value="1">Today</option>
            <option value="2">Yesterday</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 90 days</option>
          </select>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 2xl:grid-cols-3 2xl:gap-7.5 ">
          {/* users */}
          <div className="rounded-sm border shadow-inner border-stroke p-4  dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaUsers className="text-blue-500 text-3xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {userInfoLoading ? "..." : `${userInfo.userCount}`}
                </h4>
                <span className="text-sm font-medium">Total Users</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                {/* {userInfo.userCount} */}
              </span>
            </div>
          </div>
          {/* games */}
          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaGamepad className="text-blue-500 text-4xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {gamesInfoLoading ? "..." : `${gamesInfo}`}
                </h4>
                <span className="text-sm font-medium">Total games</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                {/* {gamesInfo} */}
              </span>
            </div>
          </div>
          {/* deposit by users */}
          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaWallet className="text-blue-500 text-3xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {depositsByUserLoading
                    ? "..."
                    : `${
                        depositsByUser?.length && depositsByUser[0].totalAmount
                      }`}
                </h4>
                <span className="text-sm font-medium">Deposited By Users</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                {/* {depositsByUser.length && depositsByUser[0].totalAmount} */}
              </span>
            </div>
          </div>

          {/*Deposited By Admin */}

          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaWallet className="text-blue-500 text-3xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {depositsByAdminLoading
                    ? "..."
                    : `${
                        depositsByAdmin?.length &&
                        depositsByAdmin[0].totalAmount
                      }`}
                </h4>
                <span className="text-sm font-medium">Deposited By Admin</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                {/* {depositsByAdmin.length && depositsByAdmin[0].totalAmount} */}
              </span>
            </div>
          </div>

          {/* withdrawal */}

          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaWallet className="text-blue-500 text-3xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {withdrawalInfoLoading
                    ? "..."
                    : `${
                        withdrawalInfo.totalWithdrawal?.length &&
                        withdrawalInfo.totalWithdrawal[0].totalAmount
                      }`}
                </h4>
                <span className="text-sm font-medium">Total Withdrawal</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                {/* 0.43% */}
              </span>
            </div>
          </div>
          {/* Ludo */}
          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaDice className="text-blue-500 text-4xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {ludoInfoLoading ? "..." : `${ludoInfo?.pendingResult}`}
                </h4>
                <span className="text-sm font-medium">Ludo Result pending</span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                Active: {ludoInfoLoading ? "..." : `${ludoInfo?.active}`}
              </span>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                Playing: {ludoInfoLoading ? "..." : `${ludoInfo?.playing}`}
              </span>
            </div>
          </div>
          {/* Tournaments */}
          <div className="rounded-sm border border-stroke p-4 shadow-inner dark:border-strokedark dark:shadow-none sm:p-6">
            <div className="flex h-11.5 w-11.5 items-center justify-center rounded-full bg-blue-100 dark:bg-meta-4">
              <FaFlagCheckered className="text-blue-500 text-3xl" />
            </div>
            <div className="mt-4 flex items-end justify-between">
              <div>
                <h4 className="text-2xl font-bold text-black dark:text-white">
                  {tournamentInfoLoading
                    ? "..."
                    : `${tournamentInfo?.pendingTournament}`}
                </h4>
                <span className="text-sm font-medium">
                  Tournament Pending Result
                </span>
              </div>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                Active:
                {tournamentInfoLoading
                  ? "..."
                  : ` ${tournamentInfo?.activeTournament}`}
              </span>
              <span className="flex items-center gap-1 text-sm font-medium text-green-500 undefined ">
                Playing:{" "}
                {tournamentInfoLoading
                  ? "..."
                  : `${tournamentInfo?.playingTournament}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Card1>
  );
}

export default Home;
