import React from "react";

function Card1(props) {
  return (
    <div class="rounded-sm border shadow-inner min-h-screen border-stroke bg-white p-3 md:p-7.5   dark:border-strokedark dark:bg-boxdark">
      {/* <h3 className="text-2xl capitalize whitespace-nowrap  font-semibold text-black dark:text-white">
        {props.title}
      </h3> */}
      {/* <div className="border-b border-stroke py-2 mb-12 px-6.5 dark:border-strokedark"></div> */}

      {props.children}
    </div>
  );
}

export default Card1;
