import axios from "axios";
import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { data } from "../../Data";
import Card1 from "../../components/Card1";

function ReferralSettingsUpdate({ closeModal, documentId }) {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  // const { documentId } = useParams();
  const [referrerAmount, setReferrerAmount] = useState();
  const [userAmount, setUserAmount] = useState("");
  const [referrShare, setReferrShare] = useState("");
  const [formData, setFormData] = useState({
    referrerAmount: 0,
    userAmount: 0,
  });

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setIsloading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/referrel`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setFormData({
          referrerAmount:
            response.data.responseData.referrelData.referrerAmount,
          userAmount: response.data.responseData.referrelData.userAmount,
        });
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();

    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/admin/referrel/${documentId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.responseStatusCode === 403) {
      return navigate("/login");
    }
    alert(response.data.responseMessage);
    window.location.reload();
    navigate(`/settings`);
  };

  return (
    <form
      onSubmit={submitHandler}
      className="flex flex-col items-start min-w-[400px] text-lg  max-w-lg bg-white dark:bg-boxdark rounded-lg border border-stroke px-8 py-8  dark:text-white md:py-15"
    >
      <div>
        <label for="referrerAmount" className="mt-4 text-zinc-700 font-bold">
          Referrer Amount:
        </label>
        <input
          id="referrerAmount"
          name="referrerAmount"
          type="number"
          value={formData.referrerAmount}
          onChange={handleChange}
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />
        <label className="mt-4 text-zinc-700 font-bold" for="userAmount">
          User Amount:
        </label>
        <input
          type="number"
          name="userAmount"
          id="userAmount"
          value={formData.userAmount}
          onChange={handleChange}
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
        />

        <div class="-mx-3 mt-10 flex flex-wrap ">
          <div class="w-1/2  px-3">
            <button
              type="submit"
              className={`block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90`}
            >
              Submit
            </button>
          </div>
          <div class="w-1/2  px-3">
            <button
              onClick={closeModal}
              class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ReferralSettingsUpdate;
