import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { format } from "date-fns";
import Modal from "react-modal";
import Card1 from "../../components/Card1";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [searchBy, setSearchBy] = useState("");
  const [from, SetFrom] = useState("");
  const [to, setTo] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [reRender, setReRender] = useState(false);
  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    const getchUsers = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/v1/admin/users?search=${searchBy}&from=${from}&to=${to}&skip=${
            (currentPage - 1) * dataPerPage
          }&limit=${dataPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        setUsers(response.data.responseData.users);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      } catch (error) {
        return navigate("/error");
      }
    };

    getchUsers();
  }, [currentPage, to, from, searchBy, reRender]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const deleteUser = async (userId) => {
    try {
      setIsloading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsloading(false);
      setReRender(!reRender);
      alert(response.data.responseMessage);
    } catch (error) {
      return navigate("/error");
    }
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };

  return (
    <>
      <Card1 title={"Active Users"}>
        <div className="max-w-full  overflow-x-auto your-container-class">
          <div className="flex  justify-start pb-5 my-5 gap-10 ">
            <div className="">
              <input
                type="text"
                className="form-datepicker w-60 rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                placeholder="search by name/mobile number"
                onChange={(e) =>
                  setSearchBy(
                    e.target.value.replace(/([.?*+^$[\]\\(){}|-])/g, "")
                  )
                }
              />
            </div>
            <div className=" whitespace-nowrap ">
              From:
              <input
                type="date"
                className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                onChange={(e) => SetFrom(e.target.value)}
              />
            </div>
            <div className="whitespace-nowrap">
              To:
              <input
                type="date"
                className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <div className="max-w-full  overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="border-b border-stone-300">
                  <tr className="bg-gray shadow-inner  text-left dark:bg-meta-4">
                    <th className="whitespace-nowrap py-4  px-4 font-bold  text-black dark:text-white ">
                      Sr.
                    </th>
                    <th className="whitespace-nowrap  py-4 px-1 font-bold  text-black dark:text-white ">
                      User
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white "></th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      MobileNo
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Email
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Referred By
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Total Amount
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Deposit
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Amount Won
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Amount Withdrawn
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[160px] py-4 px-1 font-bold  text-black dark:text-white ">
                      Ludo Level
                    </th>

                    <th className="whitespace-nowrap text-center  py-4 px-1 font-bold  text-black dark:text-white ">
                      Time
                    </th>
                    <th className="whitespace-nowrap text-center py-4 px-1 font-bold  text-black dark:text-white ">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((el, i) => (
                    <tr key={el._id} className="hover:bg-gray-2">
                      <td className="border-b border-[#eee] text-sm  px-4   dark:border-stone-300dark ">
                        {i + 1 + (currentPage - 1) * dataPerPage}
                      </td>
                      <td className="border-b  border-[#eee] text-sm  dark:border-stone-300dark ">
                        <img src={el.profileImage} className="w-10 h-10" />
                      </td>
                      <td
                        onClick={() => navigate(`/users/${el.id}/personalInfo`)}
                        className="border-b border-[#eee] text-sm  px-2  dark:border-stone-300dark  cursor-pointer text-blue-500 hover:underline capitalize"
                      >
                        {el.name}
                      </td>
                      <td className="border-b border-[#eee] text-sm  px-1 pr-4  dark:border-stone-300dark ">
                        {el.mobileNo}
                      </td>
                      <td className="border-b border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {el.email}
                      </td>
                      <td
                        onClick={() => {
                          el.invitedById &&
                            navigate(`/users/${el.invitedById}/personalInfo`);
                        }}
                        className="border-b cursor-pointer text-blue-500 hover:underline border-[#eee] text-sm  px-1  dark:border-stone-300dark  capitalize"
                      >
                        {el.invitedByName}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {(
                          el.wallet.totalAmountDeposited +
                          el.wallet.totalAmountWon +
                          el.wallet.myBonus
                        ).toFixed(2)}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {el.wallet.totalAmountDeposited.toFixed(2)}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {el.wallet.totalAmountWon.toFixed(2)}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {el.wallet.totalAmountWithdrawn.toFixed(2)}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        {el.ludoLevel}
                      </td>

                      <td className="border-b border-[#eee] text-sm whitespace-nowrap  px-1 pr-4  dark:border-stone-300dark ">
                        {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        <div className="flex gap-2">
                          <Tippy content="Delete user">
                            <button
                              onClick={() => {
                                setUserId(el.id);
                                setIsOpen(true);
                              }}
                              className="inline-flex text-sm items-center justify-center bg-danger py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <MdDelete />
                            </button>
                          </Tippy>

                          <Tippy content="Add Money">
                            <button
                              onClick={() => navigate(`/users/${el.id}/wallet`)}
                              className="inline-flex text-sm items-center justify-center bg-success py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <FaMoneyCheck />
                            </button>
                          </Tippy>

                          <Tippy content="Referrer Percentage & Deduction Percentage">
                            <button
                              onClick={() =>
                                navigate(`/users/${el.id}/otherInfo`)
                              }
                              className="inline-flex text-sm items-center justify-center bg-yellow-500 py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <IoMdSettings />
                            </button>
                          </Tippy>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
              >
                <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
                  <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
                    Confirm Action
                  </h3>
                  <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

                  <p class="mb-10">
                    Are you sure you want to delete this user? After deletion,
                    the user will no longer have access to the app
                  </p>
                  <div class="-mx-3 flex flex-wrap gap-y-4">
                    <div class="w-1/2  px-3">
                      <button
                        onClick={() => {
                          deleteUser(userId);
                          setIsOpen(false);
                        }}
                        class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                      >
                        Yes
                      </button>
                    </div>
                    <div class="w-1/2  px-3">
                      <button
                        onClick={() => setIsOpen(false)}
                        class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </div>

        <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
          <nav className="flex flex-wrap items-center">
            {/* Previous page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={prevPageHandler}
            >
              {"<"}
            </span>
            {/* Render page numbers */}
            {renderPages()}
            {/* Next page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={nextPageHandler}
            >
              {">"}
            </span>
          </nav>
        </div>
      </Card1>
    </>
  );
}

export default Users;
