import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import UsersNav from "./UsersNav";
import Card1 from "../../components/Card1";
import Loader from "../../components/Loader";

function UsersTournamnetsHistory() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [tournamentHistory, setTournamentHistory] = useState([]);
  const [status, setStatus] = useState("");
  const [gameName, setGameName] = useState("");

  const [isLoading, setIsloading] = useState(true);

  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/v1/admin/users/${userId}/tournamentHistory?gameName=${gameName}&status=${status}&skip=${
          (currentPage - 1) * dataPerPage
        }&limit=${dataPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setTournamentHistory(response.data.responseData.tournamentHistory);
        setTotalDataCount(response.data.responseData.dataCount);

        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, [status, gameName, currentPage]);
  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1>
      <div>
        <UsersNav userId={userId} />
      </div>
      <div>
        <div className="w-full  pb-4 flex flex-col md:flex-row  md:justify-between md:items-center  ">
          <div className="whitespace-nowrap space-x-4 max-w-full  overflow-x-auto your-container-class">
            <select
              className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(event) => setGameName(event.target.value)}
              value={gameName}
            >
              <option value={null}>Select Game</option>
              <option value="BGMI">BGMI</option>
              <option value="BGMI TDM">BGMI TDM</option>
              <option value="Freefire MAX">Freefire MAX</option>
              <option value="Freefire TDM">Freefire TDM</option>
              <option value="Call Of Duty">Call Of Duty</option>
              <option value="Call Of Duty TDM">Call Of Duty TDM</option>
              <option value="APEX Legend">APEX Legend</option>
              <option value="APEX Legend TDM">APEX Legend TDM</option>
            </select>
            <select
              className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(event) => setStatus(event.target.value)}
              value={status}
            >
              <option>Status</option>
              <option>All</option>
              <option value="cancel">cancel</option>
              <option value="active">Active</option>
              <option value="active">Active</option>
              <option value="playning">Playning</option>
              <option value="finished">Finished</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="max-w-full  overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="border-b border-stone-300">
                <tr className="bg-gray py-4 shadow-inner  text-left dark:bg-meta-4">
                  <th className="whitespace-nowrap px-4 py-4 font-medium text-black dark:text-white ">
                    Sr.
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Banner Image
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Game Name
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Game ID
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Challenge Type
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Mode
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Map
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Title
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Description
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Entry Fee
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Total Participants
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Total Participants Joined
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Room Id
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Password
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Status
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Coin/Kill
                  </th>
                  <th className="px-4 py-4 font-medium text-black dark:text-white xl:pl-11 whitespace-nowrap">
                    Prize Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {tournamentHistory.map((el, i) => (
                  <tr className="hover:bg-gray-2" key={el._id}>
                    <td className="border-b border-[#eee]  px-4   dark:border-strokedark ">
                      {i + 1 + (currentPage - 1) * dataPerPage}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <img src={el.uploadBanner} className="w-10 h-10"></img>
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11"></td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.gameName}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.gameName}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.mode}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.map}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.title}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.description}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.entryFee}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.totalParticipants}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.totalParticipantsjoined}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.roomId}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.password}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.status}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.coinPerkill}
                    </td>
                    <td className="border-b border-[#eee] whitespace-nowrap  px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.prizeAmount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
        <nav className="flex flex-wrap items-center">
          {/* Previous page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={prevPageHandler}
          >
            {"<"}
          </span>
          {/* Render page numbers */}
          {renderPages()}
          {/* Next page button */}
          <span
            className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
            onClick={nextPageHandler}
          >
            {">"}
          </span>
        </nav>
      </div>
    </Card1>
  );
}

export default UsersTournamnetsHistory;
