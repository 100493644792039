import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Card1 from "../../components/Card1";

const Prizepool = () => {
  const { tournamentId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [prizepools, setPrizepools] = useState([
    { from: "", to: "", prize: "" },
  ]);
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        response.data.responseData.challenge.prizePool.length === 0
          ? setPrizepools([{ from: "", to: "", prize: "" }])
          : setPrizepools(response.data.responseData.challenge.prizePool);
        setIsloading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedPrizepools = [...prizepools];
    updatedPrizepools[index][name] = value;
    setPrizepools(updatedPrizepools);
  };

  const handleAddPrizepool = () => {
    setPrizepools([...prizepools, { from: "", to: "", prize: "" }]);
  };

  const handleRemovePrizepool = (index) => {
    const updatedPrizepools = [...prizepools];
    updatedPrizepools.splice(index, 1);
    setPrizepools(updatedPrizepools);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/tournamnet/${tournamentId}`,
        { prizePool: prizepools },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        alert("Prize Pool updated successfully");
        navigate(`/tournaments/${tournamentId}`);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Card1>
      <div className="p-10">
        <div className="space-y-4 max-w-fit mx-auto">
          {prizepools.map((prizepool, index) => (
            <div key={index} className="flex space-x-4">
              <input
                type="number"
                name="from"
                value={prizepool.from}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Rank From"
                className="form-datepicker w-60  rounded border-[1.5px] border-stone-500 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              <input
                type="number"
                name="to"
                value={prizepool.to}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Rank To"
                className="form-datepicker w-60  rounded border-[1.5px] border-stone-500 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              <input
                type="number"
                name="prize"
                value={prizepool.prize}
                onChange={(event) => handleInputChange(index, event)}
                placeholder="Prize Amount"
                className="form-datepicker w-60  rounded border-[1.5px] border-stone-500 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              />
              {index === prizepools.length - 1 ? (
                <div className="space-x-4 whitespace-nowrap">
                  {index > 0 && (
                    <button
                      onClick={() => handleRemovePrizepool(index)}
                      className="inline-flex  items-center justify-center bg-danger w-30  py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                    >
                      Remove
                    </button>
                  )}
                  <button
                    onClick={handleAddPrizepool}
                    className="inline-flex  items-center justify-center bg-success w-30  py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                  >
                    Add
                  </button>
                </div>
              ) : (
                index > 0 && (
                  <button
                    onClick={() => handleRemovePrizepool(index)}
                    className="inline-flex  items-center justify-center bg-danger w-30  py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                  >
                    Remove
                  </button>
                )
              )}
            </div>
          ))}
          <div className="space-x-4 flex justify-center  ">
            <button
              onClick={handleSubmit}
              className="inline-flex  items-center justify-center bg-primary w-full py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Card1>
  );
};

export default Prizepool;
