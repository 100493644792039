import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card1 from "../../components/Card1";

function AddGame() {
  const navigate = useNavigate();
  const [gameName, setGameName] = useState("");
  const [type, setType] = useState("");
  const [rule, setRule] = useState("");
  const [logo, setLogo] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/games`,
        {
          gameName,
          type,
          rule,
          status: true,
          logo: imageUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.responseStatusCode === 403) {
        return navigate("/login");
      }
      navigate(`/games`);
    } catch (error) {
      console.log({ error });
    }
  };

  const uploadImage = async (e) => {
    const imageFile = e.target.files[0]; // Retrieve the file from the event

    const formData = new FormData(); // Create FormData object
    formData.append("image", imageFile); // Append the image file with the correct field name

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setImageUrl(response.data.responseData.data.imgUrl);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card1 title={"Add New Game"}>
      <form onSubmit={submitHandler}>
        <div className="flex justify-between border-stroke border-b mb-5  py-2 font-semibold text-2xl">
          Add new game
        </div>
        <div className="border border-stroke p-6.5">
          <div className="flex flex-col lg:flex-row-reverse">
            <div className="flex-1">
              <img
                src={imageUrl}
                alt="banner"
                className="min-h-[200px] w-full max-w-sm mx-auto ring-stroke ring-8 m-5 mt-2"
              ></img>
            </div>
            <div className="flex-1">
              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label
                    className="font-semibold capitalize"
                    htmlFor="gameName"
                  >
                    Game Name
                  </label>
                  <input
                    type="text"
                    name="gameName"
                    id="gameName"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={(e) => setGameName(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="type">
                    Type
                  </label>
                  <input
                    type="text"
                    name="type"
                    id="type"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="rule">
                    Rule
                  </label>

                  <input
                    type="text"
                    name="rule"
                    id="rule"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    required
                    onChange={(e) => setRule(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                <div className="w-full ">
                  <label className="font-semibold capitalize" htmlFor="logo">
                    logo
                  </label>
                  <input
                    type="file"
                    name="logo"
                    id="logo"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                    placeholder=" "
                    onChange={(e) => uploadImage(e)}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="inline-flex items-center justify-center rounded-md bg-primary py-2 px-5 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </Card1>
  );
}

export default AddGame;
