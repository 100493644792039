import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

function AddAmountTouserWallet({ closeModal }) {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [amountDeposited, setTotalAmountDeposited] = useState(0);
  const [amountWon, setTotalAmountWon] = useState(0);
  const [amountBonus, setMyBonus] = useState(0);
  const [userWallet, setUserWallet] = useState({});
  const [isLoading, setIsloading] = useState(true);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setUserWallet(response.data.responseData.user.wallet);
        setIsloading(false);
      });
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/v1/admin/users/${userId}/wallet`,
      {
        amountDeposited,
        amountBonus,
        amountWon,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.responseStatus) {
      if (response.data.responseStatusCode === 403) {
        return navigate("/login");
      }
      alert(response.data.responseMessage);
      window.location.reload();
    }
  };
  return (
    <div class="md:px-12 border border-stroke w-full max-w-lg rounded-lg bg-white px-8 py-8 text-center dark:bg-boxdark md:py-15">
      <h3 class="pb-2 text-xl bg-gray-2 font-bold text-black dark:text-white sm:text-2xl">
        Add Amount To User Wallet
      </h3>
      <span class="mx-auto  mb-6 inline-block h-1 w-full rounded bg-primary"></span>

      <form
        onSubmit={submitHandler}
        className="flex flex-col items-start min-w-96 text-lg  max-w-xl"
      >
        <label for="amountDeposited" className="mt-4 text-zinc-700 font-bold">
          Deposit (
          {isLoading ? (
            <span>...</span>
          ) : (
            userWallet.totalAmountDeposited.toFixed(2)
          )}
          ):
        </label>
        <input
          id="amountDeposited"
          type="text"
          style={{ textShadow: "1px 1px 1px black" }}
          onChange={(e) => setTotalAmountDeposited(e.target.value)}
          className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />

        <label for="amountWon" className="mt-4 text-zinc-700 font-bold">
          Winning (
          {isLoading ? <span>...</span> : userWallet.totalAmountWon.toFixed(2)}
          ):
        </label>
        <input
          type="text"
          id="amountWon"
          style={{ textShadow: "1px 1px 1px black" }}
          onChange={(e) => setTotalAmountWon(e.target.value)}
          className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />

        <label for="amountBonus" className="mt-4 text-zinc-700 font-bold">
          Bonus ({isLoading ? <span>...</span> : userWallet.myBonus.toFixed(2)}
          ):
        </label>
        <input
          type="text"
          id="amountBonus"
          style={{ textShadow: "1px 1px 1px black" }}
          onChange={(e) => setMyBonus(e.target.value)}
          className="form-datepicker w-full rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        />
        <div className="w-full mt-10">
          <div class="flex justify-evenly gap-4">
            <button
              type="submit"
              class="block w-full whitespace-nowrap rounded border border-primary bg-primary py-1 px-3 text-center font-medium text-white transition hover:bg-opacity-90"
            >
              Add Amount
            </button>

            <button
              onClick={() => closeModal()}
              class="block w-full rounded border border-stroke bg-gray py-1 px-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddAmountTouserWallet;
