import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import Modal from "react-modal";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Card1 from "../../components/Card1";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function WithdrawalReq() {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [searchBy, setSearchBy] = useState("");
  const [from, SetFrom] = useState("");
  const [to, setTo] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [gameName, setGameName] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [reload, setReload] = useState(false);
  const [modalRejectIsOpen, setRejectIsOpen] = useState(false);

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);

  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");

  useEffect(() => {
    setIsloading(true);
    const getData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/v1/admin/withdrawal?search=${searchBy}&from=${from}&transactionType=${transactionType}&&to=${to}&skip=${
            (currentPage - 1) * dataPerPage
          }&limit=${dataPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setApiData(response.data.responseData.withdrawalRequests);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      } catch (error) {
        return navigate("/error");
      }
    };

    getData();
  }, [currentPage, to, from, searchBy, transactionType, gameName, reload]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const handleWithdrawReq = (resp) => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/withdrawal/${selected}`,
        {
          response: resp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }

        if (response.data.responseStatus === false) {
          return navigate("/error");
        }
        alert(response.data.responseMessage);
      })
      .then(() => navigate("/withdrawalRequests"))
      .catch((error) => {
        return navigate("/error");
      })
      .finally(() => setReload(!reload));
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1 title={"withdrawal requests"}>
      <div className="max-w-full  overflow-x-auto  your-container-class">
        <div className="flex  justify-start pb-5 my-5 gap-4">
          <input
            type="text"
            className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="search by mobile number"
            onChange={(e) =>
              setSearchBy(e.target.value.replace(/([.?*+^$[\]\\(){}|-])/g, ""))
            }
          />
          <div className="whitespace-nowrap">
            From:
            <input
              type="date"
              className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(e) => SetFrom(e.target.value)}
            />
          </div>
          <div className="whitespace-nowrap">
            To:
            <input
              type="date"
              className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <select
            className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            onChange={(event) => setTransactionType(event.target.value)}
            value={transactionType}
          >
            <option value={null}>Withdrawal Type</option>
            <option value="">All</option>
            <option value="vpa">VPA</option>
            <option value="bank_account">Bank Account</option>
          </select>
        </div>
      </div>
      <div>
        {" "}
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <div className="max-w-full overflow-x-auto">
            <table className="w-full table-auto">
              <thead className="border-b border-stone-300">
                <tr className="bg-gray text-left shadow-inner dark:bg-meta-4">
                  <th className="py-4  whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Sr. No.
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Logo
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    User Name
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Mobile No
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Type
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Account Number
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    IFSC Code
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Vpa Address
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Transaction Id
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Amount
                  </th>

                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Actions
                  </th>
                  <th className="py-4 px-4 whitespace-nowrap font-bold text-black dark:text-white xl:pl-11">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {apiData.map((el, i) => (
                  <tr key={el._id} className="hover:bg-gray-2">
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap  pl-9 dark:border-strokedark xl:pl-11">
                      {i + 1 + (currentPage - 1) * dataPerPage}
                    </td>
                    <td className="border-b h-10 border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      <img src={el.logo} className="w-8 " />
                    </td>

                    <td
                      onClick={() =>
                        navigate(`/users/${el.userRef.id}/personalInfo`)
                      }
                      className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11 hover:underline cursor-pointer text-blue-500 capitalize"
                    >
                      {el.userRef?.name || ""}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.mobileNo || ""}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.bank_details.account_number ? "bank_account" : "vpa"}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.bank_details.account_number}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.bank_details.ifsc}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.vpa_details.vpa_address}
                    </td>
                    <td className="border-b border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el._id}
                    </td>

                    <td className="border-b font-bold border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {el.amount}
                    </td>

                    <td
                      className={`border-b border-[#eee] text-sm ${
                        el.withdrawStatus == "success"
                          ? "bg-green-100"
                          : el.withdrawStatus == "Processing" ||
                            el.withdrawStatus == "Pending"
                          ? "bg-yellow-200"
                          : el.withdrawStatus == "created"
                          ? "bg-gray-100"
                          : el.withdrawStatus == "REJECTED"
                          ? "bg-red-100"
                          : ""
                      } whitespace-nowrap px-4  dark:border-strokedark text-center`}
                    >
                      {el.withdrawStatus == "pending" ? (
                        <div className="flex gap-4 text-xs items-center justify-center">
                          <Tippy content="Approve">
                            <button
                              onClick={() => {
                                setIsOpen(true);
                              }}
                              className="inline-flex text-sm items-center justify-center bg-success py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <FaCheck />
                            </button>
                          </Tippy>

                          <Tippy content="Reject">
                            <button
                              onClick={() => {
                                setRejectIsOpen(true);
                                setSelected(el._id);
                              }}
                              className="inline-flex text-sm items-center justify-center bg-danger py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <FaTimes />{" "}
                            </button>
                          </Tippy>
                        </div>
                      ) : (
                        el.withdrawStatus
                      )}
                    </td>
                    <td className="border-b  border-[#eee] text-sm whitespace-nowrap px-4 pl-9 dark:border-strokedark xl:pl-11">
                      {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                    </td>
                  </tr>
                ))}
              </tbody>
              <Modal
                isOpen={modalRejectIsOpen}
                onRequestClose={() => setRejectIsOpen(false)}
                style={customStyles}
              >
                <RejectModal
                  setRejectIsOpen={setRejectIsOpen}
                  handleWithdrawReq={handleWithdrawReq}
                />
              </Modal>
            </table>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={customStyles}
            >
              <ApproveModal
                setIsOpen={setIsOpen}
                handleWithdrawReq={handleWithdrawReq}
              />
            </Modal>
          </div>
        )}
        <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
          <nav className="flex flex-wrap items-center">
            {/* Previous page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={prevPageHandler}
            >
              {"<"}
            </span>
            {/* Render page numbers */}
            {renderPages()}
            {/* Next page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={nextPageHandler}
            >
              {">"}
            </span>
          </nav>
        </div>
      </div>
    </Card1>
  );
}

export default WithdrawalReq;
