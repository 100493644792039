import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { FcCancel } from "react-icons/fc";
import { FaEye } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Modal from "react-modal";
import Card1 from "../../components/Card1";
import customStyles from "../../js/customStyles";
import Loader from "../../components/Loader";

function Ludo() {
  const navigate = useNavigate();
  const [ludoChallenges, setLudoChallenges] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [dataPerPage, setDataPerPage] = useState(20);
  const [totalDataCount, setTotalDataCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [roomCodes, setRoomCodes] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imgOpen, setImgOpen] = useState("");
  const [cancelLudoOpen, setCancelLudoOpen] = useState(false);
  const [challengeId, setChallengeId] = useState("");
  const [reRender, setReRender] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const numOfTotalPages = Math.ceil(totalDataCount / dataPerPage);
  const token =
    sessionStorage.getItem("kings_club_admin_token") ||
    localStorage.getItem("kings_club_admin_token");
  useEffect(() => {
    setIsloading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/v1/admin/ludo?search=${search}&skip=${
          (currentPage - 1) * dataPerPage
        }&limit=${dataPerPage}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        setLudoChallenges(response.data.responseData.ludoChallenges_);
        setTotalDataCount(response.data.responseData.dataCount);
        setIsloading(false);
      })
      .catch((error) => navigate("/error"));
  }, [search, status, currentPage, reRender]);

  const prevPageHandler = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1);
  };

  const cancelLudoChallenge = (ludoId) => {
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/v1/admin/ludo/${ludoId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.responseStatusCode === 403) {
          return navigate("/login");
        }
        if (response.data.responseStatus) {
          alert(response.data.responseMessage);

          setIsloading(false);
          setReRender(!reRender);
        }
      })
      .catch((error) => {
        if (error.response) {
          return navigate("/error");
        }
      });
  };

  const renderPages = () => {
    const pagesToShow = [];

    if (numOfTotalPages <= 4) {
      for (let i = 1; i <= numOfTotalPages; i++) {
        pagesToShow.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === i ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            {i}
          </span>
        );
      }
    } else {
      if (currentPage <= 2) {
        for (let i = 1; i <= 3; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      } else if (currentPage >= numOfTotalPages - 1) {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = numOfTotalPages - 2; i <= numOfTotalPages; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
      } else {
        pagesToShow.push(
          <span
            key={1}
            onClick={() => setCurrentPage(1)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === 1 ? "active-page bg-blue-500 scale-105" : ""
            }`}
          >
            1
          </span>
        );
        pagesToShow.push(<span key="ellipsis">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagesToShow.push(
            <span
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
                currentPage === i ? "active-page bg-blue-500 scale-105" : ""
              }`}
            >
              {i}
            </span>
          );
        }
        pagesToShow.push(<span key="ellipsis">...</span>);
        pagesToShow.push(
          <span
            key={numOfTotalPages}
            onClick={() => setCurrentPage(numOfTotalPages)}
            className={`cursor-pointer flex items-center justify-center hover:border-blue-500 border border-stroke  py-[5px] px-4 font-medium hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark ${
              currentPage === numOfTotalPages
                ? "active-page bg-blue-500 scale-105"
                : ""
            }`}
          >
            {numOfTotalPages}
          </span>
        );
      }
    }

    return pagesToShow;
  };
  return (
    <Card1 title={"Ludo challenges"}>
      <div className="max-w-full  overflow-x-auto  your-container-class">
        <div className="flex  justify-start pb-5 my-5 gap-4">
          <input
            className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="Search by challenge name..."
            onChange={(e) =>
              setSearch(e.target.value.replace(/([.?*+^$[\]\\(){}|-])/g, ""))
            }
          ></input>

          <select
            className="form-datepicker w-60  rounded border-[1.5px] border-stone-300 bg-transparent px-3 py-1 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
          >
            <option value="">All</option>
            <option value="finished">Finished</option>
            <option value="cancel">Cancel</option>
            <option value="playing">Playing</option>
            <option value="active">Active</option>
            <option value="pendingResult">Pending Result</option>
          </select>
        </div>
      </div>
      <div className="min-h-screen">
        <div>
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="border-b border-stone-300">
                  <tr className="bg-gray shadow-inner text-left dark:bg-meta-4">
                    <th className="whitespace-nowrap py-4 px-4  font-bold text-black dark:text-white">
                      Sr. No.
                    </th>
                    <th className="whitespace-nowrap min-w-[150px] py-4   font-bold text-black dark:text-white ">
                      Challenge ID
                    </th>
                    <th className="whitespace-nowrap  py-4 pr-4  font-bold text-black dark:text-white ">
                      Creator Image
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Creator name
                    </th>
                    <th className="whitespace-nowrap  py-4 pr-4  font-bold text-black dark:text-white ">
                      Opponent Image
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Opponent name
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Creator Win Amount
                    </th>
                    <th className="whitespace-nowrap min-w-[160px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Opponent Win Amount
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Mode
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Theme
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Level
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Coins
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Room Code
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Winner
                    </th>
                    <th className="whitespace-nowrap min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Result Declared by
                    </th>
                    <th className="whitespace-nowrap  py-4 pr-4  font-bold text-black dark:text-white ">
                      Status
                    </th>
                    <th className="whitespace-nowrap text-center min-w-[100px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Created At
                    </th>

                    <th className="whitespace-nowrap text-center min-w-[160px] py-4 pr-4  font-bold text-black dark:text-white ">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ludoChallenges.map((el, i) => (
                    <tr key={el._id} className="hover:bg-gray-2">
                      <td className="border-b border-[#eee] text-sm  px-4  dark:border-strokedark  ">
                        {i + 1 + (currentPage - 1) * dataPerPage}
                      </td>
                      <td
                        className="border-b border-[#eee] text-sm    dark:border-strokedark  hover:underline cursor-pointer text-blue-500 capitalize"
                        onClick={() => navigate(`/ludo/${el.id}`)}
                      >
                        {el.ludoChalengeId}
                      </td>
                      <td
                        className="border-b border-[#eee] text-sm    dark:border-strokedark  cursor-pointer"
                        onClick={() => {
                          setImgUrl(el.creatorImage);
                          setImgOpen(true);
                        }}
                      >
                        <img
                          src={el.creatorImage}
                          className="w-10 h-10 mx-auto"
                        />
                      </td>
                      <td
                        onClick={() =>
                          navigate(
                            `/users/${el.players?.creatorId?.id}/personalInfo`
                          )
                        }
                        className="border-b border-[#eee] text-sm    dark:border-strokedark  hover:underline cursor-pointer text-blue-500 capitalize"
                      >
                        {el.players?.creatorId?.name}
                      </td>
                      <td
                        className="border-b border-[#eee] text-sm    dark:border-strokedark  cursor-pointer"
                        onClick={() => {
                          setImgUrl(el.opponentImage);
                          setImgOpen(true);
                        }}
                      >
                        <img
                          src={el.opponentImage}
                          className="w-10 h-10 mx-auto"
                        />
                      </td>

                      <td
                        onClick={() =>
                          navigate(
                            `/users/${el.players?.opponentId?.id}/personalInfo`
                          )
                        }
                        className="border-b border-[#eee] text-sm    dark:border-strokedark  hover:underline cursor-pointer text-blue-500 capitalize"
                      >
                        {el.players?.opponentId?.name}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm    dark:border-strokedark ">
                        {el.winAmount?.creatorWinningAmount}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm    dark:border-strokedark ">
                        {el.winAmount?.opponentWinningAmount}
                      </td>
                      <td className="border-b border-[#eee] text-sm    dark:border-strokedark ">
                        {el.selectMode}
                      </td>
                      <td className="border-b border-[#eee] text-sm    dark:border-strokedark ">
                        {el.selectTheme}
                      </td>
                      <td className="border-b border-[#eee] text-sm    dark:border-strokedark ">
                        {el.selectLevel}
                      </td>

                      <td className="border-b text-center border-[#eee] text-sm    dark:border-strokedark ">
                        {el.coins}
                      </td>

                      <td
                        className="border-b border-[#eee] text-sm  cursor-pointer  dark:border-strokedark "
                        onClick={() => {
                          setRoomCodes(el.roomCodeHistory);
                          openModal();
                        }}
                      >
                        {el.roomCode}
                      </td>
                      {/* winner */}
                      <td className="border-b border-[#eee] text-sm    dark:border-strokedark ">
                        {el.creatorWin === true
                          ? `Creator`
                          : el.opponentWin === true
                          ? `Opponent`
                          : ""}
                      </td>
                      {/* result declared by */}
                      <td className="border-b border-[#eee] text-sm    dark:border-strokedark ">
                        {el.creatorUpdatedResult === true &&
                        el.adminStatus === ""
                          ? `Creator`
                          : el.opponentUpdatedResult === true &&
                            el.adminStatus === ""
                          ? `${"Opponent"}`
                          : (el.opponentUpdatedResult === true ||
                              el.creatorUpdatedResult === true) &&
                            el.adminStatus === "admin"
                          ? "admin"
                          : ""}
                      </td>
                      <td className="border-b capitalize border-[#eee] text-sm    dark:border-strokedark ">
                        {el.status}
                      </td>
                      <td className="border-b pl-10 border-[#eee] text-sm whitespace-nowrap   dark:border-strokedark ">
                        {format(el.createdAt, "📆dd-MM-yyyy  🕐k:m:s")}
                      </td>
                      <td className="border-b text-center border-[#eee] text-sm  px-1  dark:border-stone-300dark ">
                        <div className="flex justify-center gap-2">
                          <Tippy content="view">
                            <button
                              onClick={() => navigate(`/ludo/${el.id}`)}
                              className="inline-flex text-sm items-center justify-center bg-primary py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded"
                            >
                              <FaEye />
                            </button>
                          </Tippy>
                          <Tippy content="Cancel">
                            <button
                              onClick={() => {
                                setCancelLudoOpen(true);
                                setChallengeId(el.id);
                              }}
                              className={`inline-flex text-sm items-center justify-center bg-danger py-1 px-3 text-center font-medium text-white hover:bg-opacity-90 rounded ${
                                el.status === "cancel" ||
                                el.status === "finished"
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              <TiCancel />
                            </button>
                          </Tippy>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                  // contentLabel="Example Modal"
                >
                  <div class=" rounded-lg md:max-w-lg  bg-white md:px-8 py-6 text-center dark:bg-boxdark md:py-8">
                    <h3 class="pb-2 text-xl capitalize font-bold text-black dark:text-white sm:text-2xl">
                      Room code update history
                    </h3>
                    <span class="mx-auto mb-6 inline-block h-1 w-full rounded bg-primary"></span>

                    <div className="flex flex-col justify-between min-h-[300px] border border-stroke">
                      <div>
                        {roomCodes.map((el) => (
                          <div className="flex gap-5 py-4 border-b border-[#eee]  md:px-4  dark:border-strokedark ">
                            <div>
                              <span className="md:mr-4">🟢</span> {el.code}
                            </div>
                            <div>
                              {format(el.date, "📆dd-MM-yyyy  🕐k:m:s")}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="">
                        <button
                          onClick={() => setIsOpen(false)}
                          class="block w-full rounded border border-stroke bg-gray py-2 px-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </table>
            </div>
          )}
          <Modal
            isOpen={imgOpen}
            onRequestClose={() => setImgOpen(false)}
            style={customStyles}
            // contentLabel="Example Modal"
          >
            <div class=" rounded-lg md:max-w-lg max-h-[80%]  overflow-auto bg-white p-3 text-center dark:bg-boxdark">
              <div className="text-right">
                <button
                  className="ring bg-red-500 mb-2 px-2 text-black"
                  onClick={() => setImgOpen(false)}
                >
                  Close
                </button>
              </div>
              <div className="w-full">
                <img src={imgUrl} className="w-full"></img>
              </div>
            </div>
          </Modal>
        </div>
        <Modal
          isOpen={cancelLudoOpen}
          onRequestClose={() => setCancelLudoOpen(false)}
          style={customStyles}
        >
          <div class=" rounded-lg md:max-w-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:py-15">
            <h3 class="pb-2 text-xl font-bold text-black dark:text-white sm:text-2xl">
              Confirm Action
            </h3>
            <span class="mx-auto mb-6 inline-block h-1 w-22.5 rounded bg-primary"></span>

            <p class="mb-10">
              Are you sure you want to cancel the challenge? After cancellation,
              the amount will be refunded to participants.
            </p>
            <div class="-mx-3 flex flex-wrap gap-y-4">
              <div class="w-1/2  px-3">
                <button
                  onClick={() => {
                    setCancelLudoOpen(false);
                    cancelLudoChallenge(challengeId);
                  }}
                  class="block w-full rounded border border-primary bg-primary p-3 text-center font-medium text-white transition hover:bg-opacity-90"
                >
                  Yes
                </button>
              </div>
              <div class="w-1/2  px-3">
                <button
                  onClick={() => setCancelLudoOpen(false)}
                  class="block w-full rounded border border-stroke bg-gray p-3 text-center font-medium text-black transition hover:border-meta-1 hover:bg-meta-1 hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-white dark:hover:border-meta-1 dark:hover:bg-meta-1"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="pt-4 flex flex-col md:flex-row justify-center items-baseline">
          <nav className="flex flex-wrap items-center">
            {/* Previous page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-l-md border border-stroke  hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={prevPageHandler}
            >
              {"<"}
            </span>
            {/* Render page numbers */}
            {renderPages()}
            {/* Next page button */}
            <span
              className="cursor-pointer flex h-9 w-9 items-center justify-center rounded-r-md border border-stroke   hover:bg-gray hover:text-primary dark:border-strokedark dark:hover:border-primary dark:hover:bg-graydark"
              onClick={nextPageHandler}
            >
              {">"}
            </span>
          </nav>
        </div>
      </div>
    </Card1>
  );
}

export default Ludo;
