import React from "react";

const Error = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p className="text-xl text-gray-600 animate-pulse">
        Oops! Something went wrong.
      </p>
    </div>
  );
};

export default Error;
